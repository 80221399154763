import {
	useState,
	useRef,
	useEffect,
	createRef,
	useMemo,
} from 'react'
import {
	useNavigate,
	useLocation,
	useParams,
	useSearchParams,
} from 'react-router-dom'
import { useVenti } from 'venti'
import { setDefaults, fromAddress } from 'react-geocode'
import Resizer from 'react-image-file-resizer'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import {
	TextField,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	FormControlLabel,
	Checkbox,
	Slider,
	Box,
	RadioGroup,
	Radio,
	Button,
	Typography,
	IconButton,
	Link,
} from '@mui/material'
import Page from '../../components/Page'
import { useAppContext } from '../../components/AppContext'
import { ModalYesNo } from '../../components/modals/ModalYesNo'
import { getTheme } from '../../config'
import {
	getRouteNameFromPath,
	navigationLinking,
} from '../../services/navigation'
import {
	states,
	textIsValid,
	getErrorMessage,
	getValidationMessage,
	isDarkMode,
	findWorkflowProperties,
	borrowerRelationships,
	borrowerTypes,
	formTypes,
	iterateObject,
} from '../../services/helper'
import {
	generateRandomPassword,
	applyMask,
	isJsonString,
	setAutoFocusProp,
} from '../../services/utils'
import {
	authenticate,
	getBranchLoanOfficers,
	getCorporateLoanOfficers,
	getFormsBySiteConfig,
	getMe,
	registerBorrower,
	createLoanApplication,
	sendBorrowerInvitation,
	getCorporateBranches,
	saveLoanDraft,
	Roles,
	getForm,
	getLoanData,
	deleteLoanDraft,
	searchListings,
	createFormSubmission,
} from '../../services/client'
import testQuestions from '../../workflows/test-app.json'
import {
	fillRecursiveQuestions,
	testConditions,
} from '../../services/appService'
import { Button as CustomButton } from '../../components/Button'
import { useAlert, useWindowSize } from '../../hooks'
import { Place } from '../../components/formControls/Place'
import { Loading } from '../../components/Loading'
import { useAppContextActions } from '../../components/AppContext/AppHooks'
import { LinearProgressWithLabel } from '../../components/ProgressBar'
import { ModalSuccess } from '../../components/modals/ModalSuccess'
import {
	ArrowBack,
	ArrowDownwardOutlined,
	ArrowUpwardOutlined,
	Edit,
	Home,
} from '@mui/icons-material'
import PublishSubscribe from 'publish-subscribe-js'
import { some, isString, isBoolean } from 'lodash'
import { useMixpanel } from '../../hooks/useMixpanel'
import { eventTypes } from '../../services/constants'

const theme = getTheme()

setDefaults({
	key: theme.google.apiKey,
	language: theme.google.language,
	region: theme.google.region,
})

export const routeTypes = [
	'Prequalify', // 0
	'Apply', // 1
	'InviteApply', // 2
	'LOApply', // 3
	'LOApplyCoBorrower', // 4
	'InvitePrequalify', // 5
	'PrequalifySpanish', // 6
	'ApplySpanish', // 7
	'LOPrequal', // 8
	'LOPrequalCoBorrower', // 9
	'LOLeadApp', // 10
	'LeadApp', // 11
	'PrequalifyCoBorrower', // 12
	'ApplyCoBorrower', // 13
	'OpenHouseWithListing', // 14
	'BuyerClient', // 15
	'SellerClient', // 16
	'ClientLifestyle', // 17
	'Walmart', // 18
	'OpenHouse', // 19
	'EConsent', // 20
	'RealtorSignUp', // 21
	'OpenHouseNoAddress', // 22
	'ListingOffer', // 23
	'ListingOfferWithListing', // 24
	'LOPrequalSpanish', // 25
	'LOPrequalCoBorrowerSpanish', // 26
	'LOApplySpanish', // 27
	'LOApplyCoBorrowerSpanish', // 28
	'AgentReferral', // 29
	'InvitePrequalifySpanish', // 30
	'InviteApplySpanish', // 31
]

const resetRoutes = routeTypes.filter(
	(route) =>
		route.toLowerCase().includes('coborrower') ||
		route.substring(0, 2) === 'LO'
)

const debug = (process.env.NODE_ENV || theme.isLocalHost) && false
const debugLevel = 'default' // 'verbose'
const refs = []

export default function LoanApp({ questionsOverride, editQuestion }) {
	const { alert } = useAlert()
	const mixpanel = useMixpanel()
	const navigate = useNavigate()
	const route = useLocation()
	const params = useParams()
	const mountedRef = useRef(true)
	const ventiState = useVenti()
	const { state, dispatch } = useAppContext()
	const { applyAuthToken, applyUser, applyInvite, applyRedirectUrl } =
		useAppContextActions()
	const { siteConfig, user, invite } = state
	const loanDraft = ventiState.get(theme.storageKeys.loanDraft)
	const loanId = ventiState.get(theme.storageKeys.loanId)
	const loanData = ventiState.get(theme.storageKeys.loanData)
	const muiTheme = useTheme()
	const fullScreen = useMediaQuery(muiTheme.breakpoints.down('sm'))
	const [width] = useWindowSize()
	const authorizationRef = useRef(null)

	let [searchParams] = useSearchParams()

	const companyAddress = `${siteConfig?.companyAddress}${siteConfig?.companyAddress2 ? `, ${siteConfig?.companyAddress2},` : ''} ${siteConfig?.companyCity}, ${siteConfig?.companyState} ${siteConfig?.companyZip}`
	const routeName = getRouteNameFromPath(route.pathname)
	const isCoBorrowerByBorrower = [
		'PrequalifyCoBorrower',
		'ApplyCoBorrower',
		'LOApplyCoBorrower',
	].includes(routeName)

	let { address, listingId } = useParams()

	const initialWorkflowState = {
		id: null,
		type: null,
		borrowerType: null,
		description: null,
		formJSON: null,
		isDefault: false,
		name: null,
		slug: null,
		status: null,
		target: null,
		authType: null,
		showProgressBar: true,
	}

	const defaultAppData = {
		POSPOSApplicationType: 0,
		siteConfigurationId: siteConfig.id,
		POSBorrowerType: 0,
		name: siteConfig.name,
		companyName: siteConfig.companyName,
		companyPhone: siteConfig.companyPhone,
		loanOfficerEmail: '',
		companyAddress: companyAddress,
		shouldQueueRequest: true,
		OpenHouseAddress: '',
		additionalFields: {},
		listingID: listingId,
		formId: null,
	}

	ventiState.set(
		theme.storageKeys.redirectRoute,
		navigationLinking[routeName]
	)

	const [workflow, setWorkflow] = useState(initialWorkflowState)
	const [app, setApp] = useState(defaultAppData)
	const [questions, setQuestions] = useState([])
	const [target, setTarget] = useState('')
	const [inputButtonDisabled, setInputButtonDisabled] = useState(true)
	const [progress, setProgress] = useState(0)
	const [groupInputButtonDisabled, setGroupInputButtonDisabled] =
		useState(true)
	const [targetHistory, setTargetHistory] = useState([])
	const [validationMessage, setValidationMessage] = useState('')
	const [applicationTitle, setApplicationTitle] = useState(``)
	const [applicationHeading, setApplicationHeading] = useState(``)
	const [submitted, setSubmitted] = useState(false)
	const [successWithReload, setSuccessWithReload] = useState(false)
	const [saving, setSaving] = useState(false)
	const [groupValidations, setGroupValidations] = useState({})
	const [radioButtonDisabled, setRadioButtonDisabled] = useState(true)
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: '',
		leavePage: false,
		event: null,
	})
	const [fetchingInvite, setFetchingInvite] = useState(false)
	const [invalidJSON, setInvalidJSON] = useState(false)
	const [isLoanOfficerEntry, setIsLoanOfficerEntry] = useState(false)
	const [loanOfficerList, setLoanOfficerList] = useState([])
	const [finalTarget, setFinalTarget] = useState('')
	const [hmdaErrorMessage, sethmdaErrorMessage] = useState('')
	const [counties, setCounties] = useState([])
	const [zipcodeResults, setZipcodeResults] = useState([])
	const [borrowerEmails, setBorrowerEmails] = useState([])
	const [debugWindowOpen, setDebugWindowOpen] = useState(false)
	const [initialDraftCreation, setInitialDraftCreation] =
		useState(false)

	let questionText = ''

	const replaceVars = (str) => {
		let or
		if (str?.includes('||')) {
			let parts = str.split('||')
			str = `${parts[0].trim()}}}`
			or = parts[1].replace('}}', '').trim()
		}
		let vars = []
		let re = /{{([^}]+)}}/g
		let text

		// eslint-disable-next-line no-cond-assign
		while ((text = re.exec(str))) {
			vars.push(text[1])
		}

		vars.forEach((v) => {
			str = str.replace(`{{${v}}}`, app[v] || or)
		})

		return str
	}

	/**
	 * Set the questions for the application
	 * @param {string} workflow (optional)
	 * @param {string} formId (optional)
	 * @returns {Promise<void>}
	 */
	const setAppQuestions = async ({ workflow = '', formId = '' }) => {
		try {
			let appQuestions
			let borrowerType
			let loanOfficers = []
			let branches = []
			let listings
			let reAppData = null

			// eslint-disable-next-line default-case
			switch (siteConfig.entityType) {
				case 1:
					const corporateLoanOfficers =
						await getCorporateLoanOfficers(siteConfig.entityID)
					loanOfficers = corporateLoanOfficers
						.map((lo) => ({
							label: `${lo.firstName} ${lo.lastName}`,
							value: lo.email,
							siteId: lo.siteConfigurationIDs[0] || '',
						}))
						.sort((a, b) => (a.label > b.label ? 1 : -1))
					const corporateBranches = await getCorporateBranches(
						siteConfig.entityID
					)
					branches = corporateBranches
						.map((branch) => ({
							...branch,
							label: branch.name,
							value: branch.id,
						}))
						.sort((a, b) => (a.label > b.label ? 1 : -1))
					break
				case 2:
					const branchLoanOfficers = await getBranchLoanOfficers(
						siteConfig.entityID
					)
					loanOfficers = branchLoanOfficers
						.map((lo) => ({
							label: `${lo.firstName} ${lo.lastName}`,
							value: lo.email,
							siteId: lo.siteConfigurationIDs[0] || '',
						}))
						.sort((a, b) => (a.label > b.label ? 1 : -1))
					break
				case 4:
					const listingResult = await searchListings({
						isActive: true,
					})
					listings = listingResult.rows.map((l) => {
						let address = l.address.street
						if (l.address.unit) address += ` ${l.address.unit}`
						address += `, ${l.address.city}, ${l.address.state}`

						return { label: address, value: l.id }
					})
			}

			if (loanOfficers.length) {
				loanOfficers = loanOfficers?.filter((lo) => !!lo.siteId)
				setLoanOfficerList(loanOfficers)
			}

			// Questions being overridden?
			if (typeof questionsOverride === 'string') {
				if (isJsonString(questionsOverride)) {
					const override = JSON.parse(questionsOverride)
					if (Array.isArray(override)) {
						appQuestions = JSON.parse(questionsOverride)
						setInvalidJSON(false)
					} else setInvalidJSON(true)
				} else setInvalidJSON(true)
			} else if (debug && testQuestions.length) {
				appQuestions = testQuestions
			} else {
				let form = initialWorkflowState
				try {
					if (formId) {
						form = await getForm(formId)
						mixpanel.trackEvent(eventTypes.WORKFLOW_STARTED, {
							id: formId,
						})
					} else {
						const payload = { siteConfigurationId: siteConfig.id }
						if (routeName === 'CustomWorkflow')
							payload.slug = params.slug
						else if (routeName) {
							payload.formType = routeTypes.indexOf(
								workflow || routeName
							)
							if (payload.formType === -1) {
								setTimeout(() => {
									navigate(navigationLinking.Landing)
								}, 1000)
								return
							}
						}

						form = await getFormsBySiteConfig(payload)
					}
				} catch (e) {
					console.log(e)
				}

				if (
					!!form.id &&
					form.id !== '00000000-0000-0000-0000-000000000000'
				) {
					appQuestions = form.formJSON
					borrowerType = form.borrowerType
					setWorkflow(form)
				} else {
					switch (workflow || routeName) {
						case 'OpenHouseWithListing' || 'OpenHouse':
							borrowerType = 0
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/realtor-open-house-check-in.json')
								)
							)
							break
						// case 'OpenHouse':
						// 	borrowerType = 0
						// 	appQuestions = JSON.parse(JSON.stringify(require('../../workflows/realtor-open-house-check-in.json')))
						// 	break
						case 'BuyerClient':
							borrowerType = 0
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/realtor-client-buyer.json')
								)
							)
							break
						case 'SellerClient':
							borrowerType = 0
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/realtor-client-seller.json')
								)
							)
							break
						case 'ClientLifestyle':
							borrowerType = 0
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/realtor-client-lifestyle.json')
								)
							)
							break
						case 'Prequalify':
							borrowerType = 0
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/borrower-prequal.json')
								)
							)
							break
						case 'PrequalifyCoBorrower':
							borrowerType = 1
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/coborrower-by-borrower-prequal.json')
								)
							)
							break
						case 'Apply':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/borrower-app.json')
								)
							)
							borrowerType = 0
							break
						case 'ApplyCoBorrower':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/coborrower-by-borrower-app.json')
								)
							)
							borrowerType = 1
							break
						case 'InviteApply':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/coborrower-app.json')
								)
							)
							borrowerType = 1
							break
						case 'InviteApplySpanish':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/coborrower-app-spanish.json')
								)
							)
							borrowerType = 1
							break
						case 'InvitePrequalify':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/coborrower-prequal.json')
								)
							)
							borrowerType = 1
							break
						case 'InvitePrequalifySpanish':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/coborrower-prequal-spanish.json')
								)
							)
							borrowerType = 1
							break
						case 'LOApply':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/lo-borrower-app.json')
								)
							)
							borrowerType = 0
							break
						case 'LOApplyCoBorrower':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/lo-coborrower-app.json')
								)
							)
							borrowerType = 1
							break
						case 'PrequalifySpanish':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/coborrower-prequal-spanish.json')
								)
							)
							borrowerType = 0
							break
						case 'ApplySpanish':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/borrower-app-spanish.json')
								)
							)
							borrowerType = 0
							break
						case 'LOApplySpanish':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/lo-borrower-app-spanish.json')
								)
							)
							borrowerType = 0
							break
						case 'LOPrequal':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/lo-borrower-prequal.json')
								)
							)
							borrowerType = 0
							break
						case 'LOPrequalCoBorrower':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/lo-coborrower-prequal.json')
								)
							)
							borrowerType = 1
							break
						case 'LOLeadApp':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/lo-super-short-app.json')
								)
							)
							borrowerType = 0
							break
						case 'LeadApp':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/super-short-app.json')
								)
							)
							borrowerType = 0
							break
						case 'AgentReferral':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/agent-referral.json')
								)
							)
							borrowerType = 0
							break
						case 'Walmart':
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/walmart.json')
								)
							)
							borrowerType = 0
							break
						case 'LeadCheckIn':
							borrowerType = 0
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/lead-check-in.json')
								)
							)
							break
						case 'RealtorSignUp':
							borrowerType = -1
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/realtor-sign-up.json')
								)
							)
							break
						default:
							borrowerType = 0
							appQuestions = JSON.parse(
								JSON.stringify(
									require('../../workflows/borrower-prequal.json')
								)
							)
					}
				}

				if (user?.isLoggedIn) {
					// todo: Explicitly set fields for re-app or LO entry instead of using ...rest
					if (loanDraft?.applicationPayload.siteConfigurationId) {
						setApp(loanDraft.applicationPayload)
					} else {
						if (!isLoanOfficerEntry) {
							/** Remove the property and loan information from the loan data */
							if (loanData) {
								if (borrowerType === 0) {
									const {
										loanId,
										loanID,
										LoanID,
										LoanNumber,
										LoanOfficerEmail,
										LoanTerm,
										CurrentLoanType,
										LoanAmount,
										RefiLoanTerm,
										DownPaymentAmount,
										LoanPurpose,
										RefiLoanPurpose,
										DownPaymentGiftAmount,
										SubjectPropertyResidencyType,
										SubjectPropertyUse,
										PropertyUse,
										SubjectPropertyAddressStreet,
										SubjectPropertyAddressCity,
										SubjectPropertyAddressState,
										SubjectPropertyAddressCounty,
										SubjectPropertyAddressZip,
										SubjectPropertyNumUnits,
										SubjectPropertyPurchasePrice,
										RefiSubjectPropertyResidencyType,
										RefiSubjectPropertyAddressStreet,
										RefiSubjectPropertyAddressCity,
										RefiSubjectPropertyAddressState,
										RefiSubjectPropertyAddressZip,
										RefiSubjectPropertyYearBuilt,
										RefiSubjectPropertyNumUnits,
										RefiSubjectPropertyPurchasePrice,
										RefiSubjectPropertyExistingLiens,
										BorrowerRealEstatePropertyMortgageAmount,
										ClosingDate,
										EstimatedClosingDate,
										...rest
									} = loanData
									reAppData = rest
								} else if (loanData.BorrowerHomeAddressStreet) {
									setApp((current) => ({
										...current,
										PrimaryBorrowerHomeAddressStreet:
											loanData.BorrowerHomeAddressStreet || '',
									}))
								}
							}
						} else if (!resetRoutes.includes(routeName)) {
							if (loanData) {
								if (borrowerType === 0) {
									const {
										loanId,
										loanID,
										LoanID,
										LoanNumber,
										LoanOfficerEmail,
										LoanTerm,
										CurrentLoanType,
										LoanAmount,
										RefiLoanTerm,
										DownPaymentAmount,
										LoanPurpose,
										RefiLoanPurpose,
										DownPaymentGiftAmount,
										SubjectPropertyResidencyType,
										SubjectPropertyUse,
										PropertyUse,
										SubjectPropertyAddressStreet,
										SubjectPropertyAddressCity,
										SubjectPropertyAddressState,
										SubjectPropertyAddressCounty,
										SubjectPropertyAddressZip,
										SubjectPropertyNumUnits,
										SubjectPropertyPurchasePrice,
										RefiSubjectPropertyAddressStreet,
										RefiSubjectPropertyResidencyType,
										RefiSubjectPropertyAddressCity,
										RefiSubjectPropertyAddressState,
										RefiSubjectPropertyAddressZip,
										RefiSubjectPropertyYearBuilt,
										RefiSubjectPropertyNumUnits,
										RefiSubjectPropertyPurchasePrice,
										RefiSubjectPropertyExistingLiens,
										BorrowerRealEstatePropertyMortgageAmount,
										ClosingDate,
										EstimatedClosingDate,
										...rest
									} = loanData
									reAppData = rest
								} else if (loanData.BorrowerHomeAddressStreet) {
									setApp((current) => ({
										...current,
										PrimaryBorrowerHomeAddressStreet:
											loanData.BorrowerHomeAddressStreet,
									}))
								}
							}
						}

						/** Prefill the user's info if they are a borrower filling out a primary application and its not a draft */
						if (
							borrowerType === 0 &&
							Roles.borrower === user.role &&
							!loanDraft?.id
						)
							setApp((current) => ({
								...current,
								BorrowerFirstName: user.firstName,
								BorrowerLastName: user.lastName,
								BorrowerEmail: user.email,
								BorrowerContactPhone: user.phone,
								BorrowerMobilePhone: user.phone,
								POSBorrowerType: borrowerType,
							}))
						else
							setApp((current) => ({
								...current,
								POSBorrowerType: borrowerType,
							}))
					}
				}
			}

			appQuestions = fillRecursiveQuestions(appQuestions)

			// State Licenses and refs
			appQuestions = appQuestions.map((q) => {
				setAutoFocusProp(q)

				if (q.type === 'group') {
					// iterate over group items
					q.items.map((item) => {
						refs[item.qId] = createRef()
						if (item.model) {
							// eslint-disable-next-line default-case
							switch (item.model) {
								case 'states':
									if (item.licensesFilter) {
										item.options = states.filter(
											(o) =>
												siteConfig.licenses?.includes(o.value) ||
												o.value === ''
										)
										if (siteConfig.licenses?.length === 1) {
											app[item.fieldId] = siteConfig.licenses[0]
										}
									} else item.options = states
									break
								case 'loanOfficers':
									item.options = loanOfficers
									break
								case 'branches':
									item.options = branches.filter(
										(branch) => branch.type === 'Mortgage'
									)
									break
								case 'realEstateBranches':
									item.options = branches.filter(
										(branch) => branch.type === 'RealEstate'
									)
									break
								case 'listings':
									item.options = listings || []
							}
						}
						return item
					})
				} else if (q.model) {
					// eslint-disable-next-line default-case
					switch (q.model) {
						case 'states':
							if (q.fieldId?.includes('PropertyAddressState')) {
								q.options = states.filter(
									(o) =>
										siteConfig.licenses?.includes(o.value) ||
										o.value === ''
								)
								if (siteConfig.licenses?.length === 1) {
									app[q.fieldId] = siteConfig.licenses[0]
								}
							} else q.options = states
							break
						case 'loanOfficers':
							q.options = loanOfficers
							break
						case 'branches':
							q.options = branches.filter(
								(branch) => branch.type === 'Mortgage'
							)
							break
						case 'realEstateBranches':
							q.options = branches.filter(
								(branch) => branch.type === 'RealEstate'
							)
							break
						case 'listings':
							q.options = listings
					}
				}
				return q
			})

			if (
				(siteConfig.entityType === 3 || loanOfficers.length === 0) &&
				!isLoanOfficerEntry
			) {
				const loQuestionIndex = appQuestions.findIndex(
					(q) => 'loanofficeremail' === q.qId.toLowerCase()
				)
				if (loQuestionIndex > -1) {
					appQuestions = appQuestions.map((q, index) => {
						if (loQuestionIndex - 2 === index)
							q.target = appQuestions[loQuestionIndex + 1].qId
						return q
					})
					const start = loQuestionIndex > 0 ? loQuestionIndex - 1 : 0
					appQuestions.splice(start, 2)
				}
			}

			// HMDA
			const hmdaQuestions = appQuestions.filter(
				(q) => q.type === 'hmda'
			)
			if (hmdaQuestions.length) {
				// Set HMDA questions default values
				hmdaQuestions.forEach((q) => {
					const hmdaData = {}
					q.options.forEach(
						(o) => (hmdaData[o.name] = !o.other ? false : '')
					)
					setApp((app) => ({ ...app, [q.fieldId]: hmdaData }))
				})
			}

			// Auth question
			const singleAuthQuestion = appQuestions.filter(
				(q) => q.type === 'authorization' && q.options?.length === 1
			)
			if (singleAuthQuestion) {
				// Set single auth question default value
				singleAuthQuestion.forEach((q) => {
					setApp((app) => ({ ...app, [q.fieldId]: false }))
				})
			}

			setQuestions(appQuestions)

			if (appQuestions.length) {
				if (reAppData) {
					const fieldIds = findWorkflowProperties({
						questions: appQuestions,
						target: 'fieldId',
					})
					Object.keys(reAppData).forEach((key) => {
						if (!fieldIds.includes(key)) delete reAppData[key]
					})
					setApp((current) => ({ ...current, ...reAppData }))
				}

				// Set initial target and default settings
				const target =
					loanDraft?.customData.nextTarget || appQuestions[0].qId
				!!appQuestions && appQuestions.length && setTarget(target)
				const title =
					loanDraft?.customData.title ||
					replaceVars(appQuestions[0]?.title || '') ||
					`Let's Get Started`
				const heading =
					loanDraft?.customData.heading ||
					replaceVars(appQuestions[0]?.heading || '') ||
					`Answer a few questions to see if you Prequalify`
				setApplicationTitle(title)
				setApplicationHeading(heading)
				setTargetHistory(
					loanDraft?.customData.targetHistory || [
						{ target, title, heading },
					]
				)
			}
		} catch (e) {
			console.log(e)
			if (typeof questionsOverride !== 'string') {
				alert(getErrorMessage(e), { severity: 'error' })
				ventiState.set(
					theme.storageKeys.errorMessage,
					'An unexpected error has occurred. Please try again later.'
				)
			} else {
				alert(getErrorMessage(e), { severity: 'error' })
				ventiState.set(
					theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error
				)
			}

			navigate(`${navigationLinking.Landing}`)
		}
	}

	const resetDefaults = async (resetAppData = false) => {
		if (resetAppData) setApp(defaultAppData)
		setQuestions([])
		setSaving(false)
		setTarget('')
		setTargetHistory([])
		setFinalTarget('')
		setGroupValidations({})
		setProgress(0)
		setApplicationTitle('')
		setApplicationHeading('')
		setFetchingInvite(false)
		setInputButtonDisabled(true)
		setInvalidJSON(false)
		setRadioButtonDisabled(true)
	}

	useMemo(() => {
		setAppQuestions({ workflow: '', formId: '' })
	}, [])

	useEffect(() => {
		PublishSubscribe.publish('CLOSE_MENU')
	}, [])

	useEffect(() => {
		if (invite?.loanID)
			setApp((current) => ({
				...current,
				BorrowerFirstName: invite.firstName,
				BorrowerLastName: invite.lastName,
				BorrowerEmail: invite.emailAddress,
				BorrowerContactPhone: invite.phoneNumber,
			}))
	}, [invite])

	useEffect(() => {
		if (
			routeName.includes('OpenHouse') &&
			!!address &&
			questions.length
		) {
			setApp((current) => ({
				...current,
				OpenHouseAddress: decodeURI(address),
			}))
		}
	}, [routeName, questions])

	useEffect(() => {
		if (loanData?.allBorrowers?.length) {
			setBorrowerEmails(
				loanData.allBorrowers.map((borrower) => borrower.email)
			)
		}
	}, [loanData])

	useEffect(() => {
		if (authorizationRef?.current?.scrollHeight <= 254)
			setRadioButtonDisabled(false)
	}, [authorizationRef])

	useEffect(() => {
		;(async () => {
			if (finalTarget) await save()
		})()
	}, [finalTarget])

	useEffect(() => {
		try {
			if (typeof questionsOverride === 'string')
				if (isJsonString(questionsOverride)) {
					;(async () =>
						await setAppQuestions({ workflow: '', formId: '' }))()
				} else {
					setInvalidJSON(true)
				}
		} catch (e) {
			console.log(e)
		}
	}, [questionsOverride])

	useEffect(() => {
		return () => {
			mountedRef.current = false
		}
	})

	useEffect(() => {
		if (!user?.isLoggedIn) {
			if (workflow.id && workflow.authType === 'RequireAuth') {
				setTimeout(
					() =>
						navigate(`/${navigationLinking.CreateAccount}`, {
							state: { redirectRoute: navigationLinking[routeName] },
						}),
					200
				)
			}
		} else
			setIsLoanOfficerEntry(
				[Roles.loanOfficer, Roles.branchManager].includes(user.role)
			)
	}, [user, workflow])

	useEffect(() => {
		debug && console.log(JSON.stringify(app))
	}, [app, debug])

	const handleScrollAuthorization = (e) => {
		const bottom =
			e.target.scrollHeight - e.target.scrollTop ===
			e.target.clientHeight
		if (bottom) {
			setRadioButtonDisabled(false)
		}
	}

	const handleChoiceButtonPress = async (event, question, option) => {
		setApp((app) => ({ ...app, [question.fieldId]: option.value }))

		if (option.route) navigate(`/${navigationLinking[option.route]}`)
		else if (option.href) window.location.href = option.href
		else await setNextTarget(option.target || question.target)
	}

	const handlePickerValueChange = (value, question) => {
		// todo: enable/disable button
		if (value?.indexOf('Select a') === 0) value = ''
		setInputButtonDisabled(!value)
		setApp((app) => ({ ...app, [question.fieldId]: value }))
	}

	const handleGroupPickerValueChange = (value, question, group) => {
		// todo: enable/disable button
		if (value?.indexOf('Select a') === 0) value = ''
		if (question.type === 'row') {
			const rowQuestion = question.items.find(
				(item) => item.type === 'picker'
			)
			setApp((app) => ({ ...app, [rowQuestion.fieldId]: value }))
		} else {
			setApp((app) => ({ ...app, [question.fieldId]: value }))
		}

		handleGroupButtonToggle(value, question, group)
	}

	// const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
	// 	return layoutMeasurement.height + contentOffset.y >= contentSize.height - 20
	// }

	const handleRadioButtonChange = (question, value) => {
		if (question.fieldId) setInputButtonDisabled(false)
		setApp((app) => ({ ...app, [question.fieldId]: value }))
	}

	const handleInputChangeText = (value, question) => {
		if (
			question.max &&
			parseInt(`${value}`.replace(/\D/g, '')) > question.max
		) {
			value = question.max
		}

		// Validate input to enable button
		let newValue = applyMask(value, question.validation)
		if (question.fieldId === 'BorrowerEmail') {
			if (
				user?.isLoggedIn &&
				![Roles.borrower].includes(user?.role) &&
				newValue.toLowerCase().trim() === user.email.toLowerCase()
			) {
				setValidationMessage(
					`Cannot use your own email address to test`
				)
				setInputButtonDisabled(true)
			} else if (
				isCoBorrowerByBorrower &&
				newValue.toLowerCase().trim() === user.email.toLowerCase()
			) {
				setValidationMessage(
					`Each borrower must have a unique email address`
				)
				setInputButtonDisabled(true)
			} else if (
				borrowerEmails.includes(newValue.toLowerCase().trim())
			) {
				setValidationMessage(
					`Each borrower must have a unique email address`
				)
				setInputButtonDisabled(true)
			} else {
				if (!!question.validation)
					setInputButtonDisabled(
						!textIsValid(newValue, question.validation)
					)
				else
					setInputButtonDisabled(
						!(
							(!question.validation || question.optional) &&
							newValue.length > 0
						)
					)
			}
		} else {
			if (!!question.validation)
				setInputButtonDisabled(
					!textIsValid(newValue, question.validation)
				)
			else
				setInputButtonDisabled(
					!(
						(!question.validation || question.optional) &&
						newValue.length > 0
					)
				)
		}

		handleInputBlur({ target: { value } }, question)

		setApp((app) => ({ ...app, [question.fieldId]: newValue }))
	}

	const handleGroupButtonToggle = (value, question, group) => {
		let valid = true
		for (let i in group.items) {
			const item = group.items[i]
			if (Boolean(item.optional)) continue
			let itemValue

			if (item.type === 'row') {
				for (let x in item.items) {
					const child = item.items[x]
					if (Boolean(child.optional)) continue
					itemValue =
						child.fieldId === question.fieldId
							? value
							: app[child.fieldId] || ''
					if (!textIsValid(itemValue, item.validation)) {
						valid = false
						break
					}
				}
			} else {
				itemValue =
					item.fieldId === question.fieldId
						? value
						: app[item.fieldId] || ''
				if (!textIsValid(itemValue, item.validation)) {
					valid = false
					break
				}
			}

			debug &&
				debugLevel === 'verbose' &&
				console.log(
					item.fieldId,
					itemValue,
					item.validation,
					textIsValid(itemValue, item.validation)
				)
		}

		setGroupInputButtonDisabled(!valid)
	}

	const handleQuestionIntegration = (val, question) => {
		// eslint-disable-next-line default-case
		switch (question.integration) {
			// Zipcode Lookup
			case 'zipcode':
				if (val?.length === 5) {
					fromAddress(val).then(({ results = [] }) => {
						if (results.length > 1) {
							const data = []
							results.forEach((result) => {
								data.push({
									[`${question.key}City`]:
										result.address_components.find(
											(comp) =>
												comp.types.includes('locality') ||
												comp.types.includes('political')
										)?.long_name || '',
									[`${question.key}County`]:
										result.address_components
											.find((comp) =>
												comp.types.includes(
													'administrative_area_level_2'
												)
											)
											?.long_name.replace(' County', '') || '',
									[`${question.key}State`]:
										result.address_components.find((comp) =>
											comp.types.includes(
												'administrative_area_level_1'
											)
										)?.short_name || '',
								})
							})
							setZipcodeResults(data)
							setCounties(
								results.map((result) =>
									result.address_components
										.find((comp) =>
											comp.types.includes(
												'administrative_area_level_2'
											)
										)
										?.long_name.replace(' County', '')
								)
							)
						} else {
							setCounties([])
							setZipcodeResults([])
						}

						const addressComponents = results[0].address_components
						const city = addressComponents.find(
							(comp) =>
								comp.types.includes('locality') ||
								comp.types.includes('neighborhood')
						)?.long_name
						const state = addressComponents.find((comp) =>
							comp.types.includes('administrative_area_level_1')
						)?.short_name
						const county = addressComponents
							.find((comp) =>
								comp.types.includes('administrative_area_level_2')
							)
							?.long_name.replace(' County', '')

						setApp((current) => ({
							...current,
							[`${question.key}City`]: city,
							[`${question.key}State`]: state,
							[`${question.key}County`]: county,
						}))
						setGroupInputButtonDisabled(false)
					})
				}
				break
		}
	}

	const handleCountyChange = (val, question) => {
		const data = zipcodeResults.find(
			(r) => `${r[question.key]}County` === val
		)
		setApp((current) => ({ ...current, ...data }))
	}

	const handleInputBlur = (event, question) => {
		if (!question.optional) {
			const value = event.target.value
			let message

			if (
				Boolean(question.validation) &&
				value?.length &&
				!textIsValid(value, question.validation)
			)
				message = getValidationMessage(question.validation)
			else if (value === undefined || value.length < 1)
				message = getValidationMessage('required')
			else if (
				question.min &&
				parseInt(`${value}`.replace(/\D/g, '')) < question.min
			)
				message = `Please enter a value greater than $${applyMask(question.min, 'currency')}`
			else if (
				question.max &&
				parseInt(`${value}`.replace(/\D/g, '')) > question.max
			)
				message = `Please enter a value less than $${applyMask(question.max, 'currency')}`

			setValidationMessage(message)
		}

		handleQuestionIntegration(event.target.value, question)
	}

	const handleGroupInputBlur = async (event, question) => {
		let message = {}
		const value = event.target.value
		message[question.qId] = false
		if (!question.optional) {
			if (
				value === undefined ||
				(!question.validation && event.target.value.length === 0)
			)
				message[question.qId] = getValidationMessage('required')
			else if (!textIsValid(value, question.validation))
				message[question.qId] = getValidationMessage(
					question.validation
				)
			else if (
				question.min &&
				parseInt(`${value}`.replace(/\D/g, '')) < question.min
			)
				message[question.qId] =
					`Please enter a value greater than $${applyMask(question.min, 'currency')}`
			else if (
				question.max &&
				parseInt(`${value}`.replace(/\D/g, '')) > question.max
			)
				message[question.qId] =
					`Please enter a value less than $${applyMask(question.max, 'currency')}`
		}

		handleQuestionIntegration(value, question)
		setGroupValidations((groupValidations) => ({
			...groupValidations,
			...message,
		}))
	}

	const handleGroupInputChangeText = async (
		value,
		question,
		group
	) => {
		let newValue = applyMask(value, question.validation)
		setApp((app) => ({ ...app, [question.fieldId]: newValue }))
		if (
			[Roles.loanOfficer, Roles.branchManager].includes(user?.role) &&
			question.fieldId === 'BorrowerEmail' &&
			value === user.email
		) {
			setValidationMessage(
				`Cannot use a Loan Officer's email address`
			)
			setInputButtonDisabled(true)
		} else {
			handleGroupButtonToggle(newValue, question, group)
		}

		await handleGroupInputBlur({ target: { value } }, question)
	}

	const handleInputKeyPress = async (event, question) => {
		handleInputBlur(event, question)
		if (event.key === 'Enter' && !inputButtonDisabled) {
			await setNextTarget(question.target)
		} else return false
	}

	const handleInputButtonPress = async (event, question) => {
		try {
			if (
				question.type === 'place' &&
				typeof app[question.fieldId] !== 'object'
			) {
				const address = {
					address1: app[question.fieldId],
					address2: 'not captured',
					city: 'not captured',
					state: 'not captured',
					zipcode: 'not captured',
				}
				setApp((app) => ({ ...app, [question.fieldId]: address }))
			}

			await setNextTarget(question.target)

			// // create user if not logged in and we're in a prequal
			// if (question.fieldId === 'BorrowerEmail' && !user?.isLoggedIn) {
			// 	await registerBorrowerAccount()
			// }
		} catch (e) {
			// registration or authentication error
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
			ventiState.set(theme.storageKeys.failedUser, true)
		}
	}

	const registerBorrowerAccount = async (
		saveAfterRegistration = false
	) => {
		try {
			const randomPW = generateRandomPassword()

			await registerBorrower({
				siteConfigurationId: siteConfig.id,
				email: app.BorrowerEmail,
				password: randomPW,
				phone: app.BorrowerContactPhone,
				firstName: app.BorrowerFirstName,
				lastName: app.BorrowerLastName,
				inviteToken: '',
				branchId: '',
				title: '',
			})

			const login = await authenticate(
				app.BorrowerEmail,
				randomPW,
				siteConfig.id
			)
			applyAuthToken(login.access_token)

			ventiState.set(theme.storageKeys.tempPassword, randomPW)

			const me = await getMe()
			const userData = { ...login, ...me }

			let authTokenExp = new Date()
			authTokenExp.setMinutes(
				authTokenExp.getMinutes() + userData.expires_in / 60 - 5
			)
			const user = {
				...userData,
				isLoggedIn: true,
				tokenExp: authTokenExp,
			}

			applyUser(user)
			ventiState.set(theme.storageKeys.authToken, login.access_token)
			ventiState.set(theme.storageKeys.user, user)
			ventiState.set(theme.storageKeys.newUser, true)

			if (saveAfterRegistration)
				await save({ bypassLoginCheck: true })
		} catch (e) {
			switch (e.status) {
				case 409:
					ventiState.set(theme.storageKeys.newUser, false)
					ventiState.set(theme.storageKeys.postLoanData, {
						...app,
						_target: target,
					})
					navigate(`/${navigationLinking.SignIn}`)
					break
				default:
					alert(getErrorMessage(e), { severity: 'error' })
					ventiState.set(
						theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error
					)
					if (saveAfterRegistration) {
						setSubmitted(false)
						setInputButtonDisabled(false)
						setGroupInputButtonDisabled(false)
						setValidationMessage(
							'Failed to obtain authentication token. Please try again later.'
						)
					}
			}
		}
	}

	const handleRangeSliderValueChange = (value, question) => {
		setApp((app) => ({ ...app, [question.fieldId]: value }))
	}

	const handleRangeButtonPress = async (event, question) => {
		await setNextTarget(question.target)
	}

	const handleGroupButtonPress = async (event, question) => {
		await setNextTarget(question.target)
	}

	const handleAddressSelection = async (
		place,
		data,
		question,
		type = 'object'
	) => {
		await geocodeByPlaceId(place.value.place_id)
			.then((results) => {
				let fieldId = question.fieldId
				let index = ''

				if (!(results[0]?.address_components || []).length) {
					setApp((app) => ({ ...app, [question.fieldId]: null }))
					return
				}

				const address = {}

				const components = {
					street_number: 'short_name',
					route: 'long_name',
					locality: 'long_name',
					administrative_area_level_1: 'short_name',
					administrative_area_level_2: 'short_name',
					country: 'long_name',
					postal_code: 'short_name',
				}

				const result = {
					street_number: '',
					route: '',
					locality: '',
					administrative_area_level_1: '',
					administrative_area_level_2: '',
					postal_code: '',
				}

				for (
					let i = 0;
					i < results[0].address_components.length;
					i++
				) {
					const addressType =
						results[0].address_components[i].types[0]
					if (components[addressType]) {
						result[addressType] =
							results[0].address_components[i][
								components[addressType]
							]
					}
				}

				let matches = question.fieldId.match(/\d+$/)

				if (matches) {
					index = matches[0]
					fieldId = fieldId.substring(
						0,
						fieldId.length - index.length
					)
				}

				if (type === 'object') {
					address.address1 = result.street_number + ' ' + result.route
					address.city = result.locality
					address.state = result.administrative_area_level_1
					address.zipcode = result.postal_code
					address.county = result.administrative_area_level_2
					setGroupInputButtonDisabled(false)

					setApp((app) => ({ ...app, [question.fieldId]: address }))
				} else if (type === 'individual') {
					setApp((app) => ({
						...app,
						[`${fieldId}Street${index}`]:
							result.street_number + ' ' + result.route,
						[`${fieldId}City${index}`]: result.locality,
						[`${fieldId}State${index}`]:
							result.administrative_area_level_1,
						[`${fieldId}Zip${index}`]: result.postal_code,
						[`${fieldId}County${index}`]:
							result.administrative_area_level_2,
					}))
					setGroupInputButtonDisabled(false)
				}
			})
			.catch((e) => {
				alert(getErrorMessage(e), { severity: 'error' })
				ventiState.set(
					theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error
				)
			})
	}

	const handleHMDASelect = (option, question) => {
		const data = app[question.fieldId]
		data[option.name] = !data[option.name]

		if (!!data[option.name]) {
			sethmdaErrorMessage('')
		}

		setApp((app) => ({ ...app, [question.fieldId]: data }))
	}

	const handleHMDAInputChangeText = (value, option, question) => {
		const data = app[question.fieldId]
		data[option.name] = applyMask(value, 'alphanumeric')

		if (!!value.trim().length) {
			sethmdaErrorMessage('')
		}

		setApp((app) => ({ ...app, [question.fieldId]: data }))
	}

	const handleHMDAButtonPress = async (event, question) => {
		const data = app[question.fieldId]
		const isOneSelected = some(data, (value) =>
			isString(value) ? !!value.trim().length : !!value
		)

		if (isOneSelected || question.optional) {
			sethmdaErrorMessage('')
		} else {
			return sethmdaErrorMessage(
				'At least one option must be selected'
			)
		}

		await setNextTarget(question.target)
	}

	const handleSingleAuthCheckbox = (option, question) => {
		setApp((app) => ({
			...app,
			[question.fieldId]: !app[question.fieldId],
		}))
	}

	const setNextTarget = async (nextTarget) => {
		/** Alter the down payment picker labels to include the amount */
		if (target === 'SubjectPropertyPurchasePrice') {
			const price = parseInt(
				`${app['SubjectPropertyPurchasePrice']}`.replace(/\D+/g, ''),
				10
			)

			const percentages = {
				0: 0,
				3: Math.round(price * 0.03),
				3.5: Math.round(price * 0.035),
				5: Math.round(price * 0.05),
				10: Math.round(price * 0.1),
				15: Math.round(price * 0.15),
				20: Math.round(price * 0.2),
			}
			setQuestions((current) =>
				current.map((q) => {
					if (
						q.fieldId === 'DownPaymentPercent' &&
						q.type === 'picker' &&
						q.options?.length
					) {
						q.options =
							q.options.map((o) => ({
								...o,
								labelOverride:
									o.value !== 'Other'
										? `${o.label} - $${applyMask(percentages[`${o.value}`], 'currency')}`
										: o.label,
							})) || []
					}
					return q
				})
			)
		}

		// find the next question byId
		const nextQ = questions.find((q) => q.qId === nextTarget)

		// Submit the form by setting the final target
		if (!nextQ) {
			setFinalTarget(nextTarget || '***POSAPPCOMPLETE***')
			return
		}

		if (nextQ.type === 'workflow') {
			await setAppQuestions({ workflow: nextQ.workflow, formId: '' })
		}

		// Move to specified or next target if condition matches (hideIf)
		if (nextQ.hideIf) {
			let conditions
			if (nextQ.hideIf.includes(' and ')) {
				conditions = nextQ.hideIf.split(' and ')
				for (let i = 0; i < conditions.length; i++) {
					if (!testConditions(conditions[i], app)) return
				}
				return setNextTarget(nextQ.targetIfHidden || nextQ.target)
			} else {
				conditions = nextQ.hideIf.split(' or ')
				for (let i = 0; i < conditions.length; i++) {
					if (testConditions(conditions[i], app))
						return setNextTarget(nextQ.targetIfHidden || nextQ.target)
				}
			}
		}

		// Set prequal progress bar
		const nextIndex = questions.findIndex((q) => q.qId === nextQ.qId)
		setProgress(
			Math.round((nextIndex / questions.length).toFixed(2) * 100)
		)

		// Set title and heading
		const title = nextQ.title
			? replaceVars(nextQ.title)
			: applicationTitle
		const heading = nextQ.heading
			? replaceVars(nextQ.heading)
			: applicationHeading
		setApplicationTitle(title)
		setApplicationHeading(heading)

		if (user?.isLoggedIn && Roles.borrower === user.role) {
			if (!initialDraftCreation) {
				if (!app.draftId) setInitialDraftCreation(true)
				try {
					const draft = await saveLoanDraft({
						siteConfigurationId: app.siteConfigurationId,
						applicationPayload: {
							...app,
							LoanID:
								app.LoanID ||
								invite?.loanID ||
								(workflow.borrowerType === 1 ? loanId : null),
						},
						customData: {
							nextTarget,
							targetHistory,
							title,
							heading,
							routeName,
						}, // todo: add additional metadata to the customData attribute for use with multiple drafts
					})
					setApp((current) => ({ ...current, draftId: draft.id }))
				} catch (e) {
					console.log(e)
				} finally {
					setInitialDraftCreation(false)
				}
			}
		}

		// Set the target question to show
		setTarget(nextTarget)

		if (nextQ.type === 'authorization') {
			setRadioButtonDisabled(nextQ.question.length > 1000)
			setInputButtonDisabled(nextQ.question.length > 1000)
		}

		// Check if q is a group or a single question
		if (nextQ.type === 'group') {
			let invalid = false
			nextQ.items.forEach((item) => {
				if (item.type !== 'row') {
					if (
						(!app[item.fieldId] && !item.optional) ||
						(app[item.fieldId] && !textIsValid(app[item.fieldId]))
					)
						invalid = true
				} else {
					item.items.forEach((rowItem) => {
						if (
							(!app[rowItem.fieldId] && !rowItem.optional) ||
							(app[rowItem.fieldId] &&
								!textIsValid(app[rowItem.fieldId]))
						)
							invalid = true
					})
				}
			})
			setGroupInputButtonDisabled(invalid)
		}

		setInputButtonDisabled(
			!nextQ.optional && nextQ.type !== 'range' && !app[nextQ.fieldId]
		)
		setValidationMessage('')
		setTargetHistory((targetHistory) => [
			...targetHistory,
			{
				target: nextTarget,
				title: nextQ.title || applicationTitle,
				heading: nextQ.heading || applicationHeading,
			},
		])
	}

	const setPrevTarget = () => {
		const prevTargetHistory = targetHistory[targetHistory.length - 2]

		// deprecation of current loan draft target history
		const targetIsObject = typeof prevTargetHistory === 'object'

		const prevTarget = targetIsObject
			? prevTargetHistory.target
			: prevTargetHistory

		const prevQ = questions.find((q) => q.qId === prevTarget)

		if (!prevQ?.qId) {
			alert('Cannot return to the previous workflow.', {
				severity: 'warning',
			})
			return
		}

		setTarget(prevTargetHistory.target)

		if (targetIsObject) {
			setApplicationTitle(prevTargetHistory.title)
			setApplicationHeading(prevTargetHistory.heading)
		} else {
			if (prevQ.title) setApplicationTitle(replaceVars(prevQ.title))
			if (prevQ.heading)
				setApplicationHeading(replaceVars(prevQ.heading))
		}

		setInputButtonDisabled(false)
		setGroupInputButtonDisabled(false)

		setTargetHistory((current) => current.slice(0, -1))
	}

	const handleErrorModalOK = () => {
		ventiState.set(theme.storageKeys.errorObject, {
			message: '',
			handleOK: null,
		})
		if (!isLoanOfficerEntry) navigate(`${navigationLinking.Landing}`)
		else {
			ventiState.set(theme.storageKeys.successMessage, '')
			ventiState.set(theme.storageKeys.successObject, {})
			ventiState.set(theme.storageKeys.errorMessage, '')
			navigate(`/${navigationLinking.Portal}`)
		}
	}

	const handleFormSubmissionErrorModalOK = () => {
		ventiState.set(theme.storageKeys.errorObject, {
			message: '',
			handleOK: null,
		})
		window.location.reload(true)
	}

	const modalConfirm = async (modalConfirmation) => {
		modalConfirmation.leavePage
			? dispatch(modalConfirmation.event.data.action)
			: setModalConfirmation((current) => ({
					...current,
					open: false,
					text: '',
					leavePage: false,
				}))
	}

	const resizeFile = (file, width, height) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				width,
				height,
				'png',
				100,
				0,
				(uri) => {
					resolve(uri)
				},
				'base64'
			)
		})

	const handleImagePicker = async (e, q) => {
		const file = e.target.files[0]
		if (file) {
			let img = new Image()
			img.src = window.URL.createObjectURL(file)

			let newWidth, newHeight
			img.onload = async () => {
				if (img.height > img.width) {
					newHeight = 300
					newWidth = newHeight * (img.width / img.height)
				} else {
					newWidth = 300
					newHeight = newWidth * (img.height / img.width)
				}

				const image = await resizeFile(file, newWidth, newHeight)

				setApp((app) => ({
					...app,
					[q.fieldId]: image,
					[`${q.fieldId}Filename`]: file.name,
					[`${q.fieldId}Dimensions`]: {
						width: newWidth,
						height: newHeight,
					},
				}))

				// setNextTarget(q.target)
			}
		}
	}

	const handleCheckboxGroupCheckboxChange = (item) => {
		if (app[item.fieldId] !== 'X')
			setApp((current) => ({ ...current, [item.fieldId]: 'X' }))
		else setApp((current) => ({ ...current, [item.fieldId]: null }))
	}

	const handleCheckboxGroupButtonPress = async (event, question) => {
		await setNextTarget(question.target)
	}

	const getPageTitle = () => {
		switch (routeName) {
			case 'LOPrequal':
				return 'New Prequal'
			case 'LOLeadApp':
				return 'New Short App'
			case 'LeadApp':
				return 'New Application'
			case 'LeadCheckIn':
				return 'Check-In'
			default:
				return 'New Application'
		}
	}

	const save = async (options = { byPassLoginCheck: false }) => {
		if (workflow.target === 'Lead') {
			try {
				const finalQuestion = questions.find((q) => q.qId === target)
				const {
					FirstName,
					LastName,
					Email,
					ContactPhone,
					LoanOfficerEmail,
					LoanOfficerID,
					SubjectPropertyAddressStreet,
					SubjectPropertyAddressCity,
					SubjectPropertyAddressState,
					SubjectPropertyAddressZip,
					SubjectPropertyAddressCounty,
					ListingID,
					BranchID,
					SiteConfigurationID,
					...rest
				} = app
				await createFormSubmission({
					FirstName,
					LastName,
					Email,
					ContactPhone,
					LoanOfficerEmail,
					LoanOfficerID,
					SubjectPropertyAddressStreet,
					SubjectPropertyAddressCity,
					SubjectPropertyAddressState,
					SubjectPropertyAddressZip,
					SubjectPropertyAddressCounty,
					ListingID,
					BranchID,
					SiteConfigurationID,
					data: rest,
					formName: routeName,
					formId: workflow.id,
				})
				mixpanel.trackEvent(eventTypes.WORKFLOW_COMPLETED, {
					id: workflow.id,
					name: routeName,
				})
				ventiState.set(theme.storageKeys.successObject, {
					message: 'Your information has been received',
					handleOK: () => {
						if (finalQuestion.navigateTo) {
							ventiState.set(theme.storageKeys.successObject, {
								message: '',
							})
							const route = finalQuestion.navigateTo
							if (navigationLinking[route]) {
								if (navigationLinking[route] !== '/')
									navigate(
										`/${navigationLinking[finalQuestion.navigateTo]}`
									)
								else navigate('/')
							} else if (
								finalQuestion.navigateTo.includes('https://')
							)
								navigate(finalQuestion.navigateTo)
							else navigate(`/${finalQuestion.navigateTo}`)
						} else {
							window.location.reload(true)
						}
					},
				})
			} catch (err) {
				console.log(err)

				ventiState.set(theme.storageKeys.errorObject, {
					message:
						'There was an error submitting the form. Please try again later.',
					handleOK: handleFormSubmissionErrorModalOK,
				})
			}

			if (loanDraft?.id) {
				await deleteLoanDraft(loanDraft.id)
				applyUser({ ...user, drafts: [] })
			}

			return
		}

		if (!user?.isLoggedIn && !options.bypassLoginCheck) {
			// user is not logged in before save so lets register the user and then save after we register the user
			return await registerBorrowerAccount(true)
		}

		const { ...data } = app

		if (invite?.loanID) {
			data.LoanID = invite.loanID
			data.Relationship = borrowerRelationships.indexOf(
				invite.relationship
			)
		} else if (data.POSBorrowerType === 1 && loanId)
			data.LoanID = loanId

		// set down payment amount if its a purchase
		if (data.LoanPurpose === 'Purchase') {
			if (!data.DownPaymentAmount && data.LoanAmount) {
				if (
					parseInt(data.DownPaymentPercent) === 0 ||
					!data.DownPaymentPercent
				)
					data.DownPaymentAmount = 0
				else
					data.DownPaymentAmount =
						(data.LoanAmount * data.DownPaymentPercent) / 100
			} else if (data.DownPaymentAmount) {
				data.DownPaymentAmount = parseFloat(
					data.DownPaymentAmount.replace(/[^0-9.]+/g, '')
				)
			}
		}

		// sanitize numeric inputs
		for (const [key, value] of Object.entries(data)) {
			if (
				key.includes('amount') ||
				key.includes('value') ||
				key.includes('price') ||
				key.includes('balance')
			)
				data[key] = parseFloat(value.toString().replace(/\D/g, ''))
		}

		// Iterate through custom fields and set additional fields object from the data
		questions.forEach((q) => {
			if (q.type === 'group') {
				q.items.forEach((i) => {
					if (i.type !== 'row') {
						if (i.isCustom) {
							data.additionalFields[i.fieldId] = app[i.fieldId]
							delete data[i.fieldId]
						}
					} else {
						i.items.forEach((r) => {
							if (r.isCustom) {
								data.additionalFields[r.fieldId] = app[r.fieldId]
								delete data[r.fieldId]
							}
						})
					}
				})
			} else if (q.type === 'checkboxGroup') {
				q.items.forEach((i) => {
					if (i.isCustom) {
						data.additionalFields[i.fieldId] = app[i.fieldId]
						delete data[i.fieldId]
					}
				})
			} else {
				if (q.isCustom) {
					data.additionalFields[q.fieldId] = app[q.fieldId]
					delete data[q.fieldId]
				}
			}
		})

		// Log and Return for Workflow Editor preview or debug mode
		if (debug || typeof questionsOverride === 'string') {
			console.log('Payload', JSON.stringify(data))
			alert('DEBUG MODE: App Complete. Check console for payload.')
			return
		}

		setSubmitted(true)
		setInputButtonDisabled(true)
		setGroupInputButtonDisabled(true)
		setValidationMessage('')

		setApplicationTitle('The Information is Being Processed')
		setApplicationHeading('Please wait while the data is validated')

		setSaving(true)

		try {
			let nextRoute = navigationLinking.Portal
			/** Send user to the portal if we're not inviting them or they are already logged in */
			if (workflow.authType !== 'InviteUser' || user?.isLoggedIn) {
				ventiState.set(
					theme.storageKeys.pleaseWaitMessage,
					'Please wait while your information is submitted. Do not refresh this page or hit the back button.'
				)
				setSubmitted(false)
				if (data.HasCoBorrower) {
					if (isLoanOfficerEntry) {
						setApplicationHeading(
							'Saving application for Primary Borrower'
						)
						nextRoute = `${routeName}CoBorrower`
					} else
						ventiState.set(
							theme.storageKeys.coBorrowerRoute,
							nextRoute
						)
				} else if (loanId) {
					setApplicationHeading('Saving application for Co-Borrower')
				}
				if (!isLoanOfficerEntry || !data.HasCoBorrower)
					setTimeout(() => {
						navigate(`/${nextRoute}`)
						ventiState.unset(theme.storageKeys.pleaseWaitMessage)
					}, 500)
			}

			/** Set the tutorial modal for a borrower or apply borrower home address if applicable for co-borrower */
			if (!isLoanOfficerEntry && !isCoBorrowerByBorrower)
				ventiState.set(theme.storageKeys.appSubmitted, true)
			else if (isCoBorrowerByBorrower) {
				ventiState.set(theme.storageKeys.coBorrowerSubmitted, true)
				if (data.IsBorrowerSameHomeAddress) {
					data.BorrowerHomeAddressStreet =
						loanData.BorrowerHomeAddressStreet
					data.BorrowerHomeAddressCity =
						loanData.BorrowerHomeAddressCity
					data.BorrowerHomeAddressState =
						loanData.BorrowerHomeAddressState
					data.BorrowerHomeAddressZip =
						loanData.BorrowerHomeAddressZip
					data.SubjectPropertyAddressCounty =
						loanData.SubjectPropertyAddressCounty
				}
			}

			if (
				data.SubjectPropertyAddressCounty?.toLowerCase().includes(
					' county'
				)
			) {
				data.SubjectPropertyAddressCounty =
					data.SubjectPropertyAddressCounty.replace(
						' County',
						''
					).replace(' county', '')
			}

			ventiState.set(theme.storageKeys.appPosting, true)

			data.additionalFields['CX.POS.WORKFLOW.NAME'] = routeName
			data.additionalFields['CX.POS.WORKFLOW.TYPE'] =
				data.POSPOSApplicationType ? 'Application' : 'Prequal'

			if (data.loanOfficerEmail) {
				const lo = loanOfficerList.find(
					(l) => l.value === data.loanOfficerEmail
				)
				if (lo) data.siteConfigurationId = lo.siteId
			} else if (siteConfig.entityType === 3) {
				data.loanOfficerEmail = siteConfig.email
			}

			applyInvite(null)

			/** Send application to server */
			const loanApplicationId = await createLoanApplication(data)

			if (user?.isLoggedIn) {
				applyUser({ ...user, drafts: [] })
			}

			// /** Reload the application if the user is invited */
			// if (workflow.authType === 'InviteUser') {
			// 	ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			// 	ventiState.set(theme.storageKeys.successObject, {
			// 		message: 'Your information has been received.',
			// 		handleOK: () => {
			// 			window.location.reload(true)
			// 		},
			// 	})
			//
			// 	return
			// }

			ventiState.set(theme.storageKeys.appPosting, false)
			ventiState.set(theme.storageKeys.appPosted, true)
			await resetDefaults(true)

			try {
				/** check if the borrower is not completing on behalf of the co-borrower */
				if (!isCoBorrowerByBorrower) {
					ventiState.set(theme.storageKeys.loanId, loanApplicationId)
					// check if its a loan officer entering the data
					if (isLoanOfficerEntry) {
						setApplicationHeading('Sending borrower invite')

						alert(
							`Application for ${data.BorrowerFirstName} ${data.BorrowerLastName} was submitted!`
						)
						await sendBorrowerInvitation({
							firstName: data.BorrowerFirstName,
							lastName: data.BorrowerLastName,
							emailAddress: data.BorrowerEmail,
							phoneNumber: data.BorrowerContactPhone,
							sourceURL: `https://${siteConfig.url}/verify/invite`,
							siteConfigurationId: siteConfig.id,
							relationship: 0,
							loanID: loanApplicationId,
						})
						alert(
							`Invitation sent to ${data.BorrowerFirstName} ${data.BorrowerLastName} <${data.BorrowerEmail}>`
						)
						ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
						if (data.HasCoBorrower) {
							setApplicationHeading('Loading Co-Borrower Application')
							const data = await getLoanData(loanApplicationId)
							ventiState.set(theme.storageKeys.loanData, data)
							await setAppQuestions({
								workflow: nextRoute,
								formId: '',
							})
						} else {
							navigate(`/${navigationLinking.Portal}`)
						}
					}
				} else {
					/** send invitation to co-borrower from the borrower submission */
					ventiState.set(theme.storageKeys.coBorrowerSubmitted, false)
					await sendBorrowerInvitation({
						firstName: data.BorrowerFirstName,
						lastName: data.BorrowerLastName,
						emailAddress: data.BorrowerEmail,
						phoneNumber: data.BorrowerContactPhone,
						sourceURL: `https://${siteConfig.url}/verify/invite`,
						siteConfigurationId: siteConfig.id,
						relationship: 0,
						loanID: loanApplicationId,
					})
					ventiState.set(
						theme.storageKeys.successMessage,
						`${data.BorrowerFirstName} was successfully added to the application. `
					)
				}
			} catch (e) {
				console.log(e)
				await resetDefaults()
				ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
				ventiState.set(theme.storageKeys.errorObject, {
					message:
						'Failed to send invitation to the borrower. Please try again later.',
				})
			}
		} catch (e) {
			console.log(e)
			await resetDefaults()
			ventiState.set(
				theme.storageKeys.redirectRoute,
				navigationLinking.Portal
			)
			ventiState.set(theme.storageKeys.appFailed, true)
			ventiState.set(theme.storageKeys.failoverModalVisible, true)
			ventiState.set(theme.storageKeys.appPosting, false)
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
			ventiState.set(theme.storageKeys.errorObject, {
				message: e?.data?.message || theme.api_messages.server_error,
				handleOK: handleErrorModalOK,
			})
		}
	}

	let fullPage = false
	if (!routeName.includes('LO')) fullPage = true

	return (
		<>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={modalConfirm}
				setModalConfirmation={setModalConfirmation}
				yesButtonName={'Yes, Leave Page'}
				noButtonName={'No, Stay Here'}
			/>
			<ModalSuccess
				visible={successWithReload}
				message={'Your information has been received'}
				setVisible={setSuccessWithReload}
				handleOk={() => window.location.reload(true)}
			/>
			<div
				className="flex justify-center items-center bg-cover"
				style={
					!siteConfig?.backgroundImageUrl
						? {
								backgroundColor: siteConfig?.backgroundColor,
								minHeight: fullPage ? '100vh' : `calc(100vh - 64px)`,
							}
						: {
								backgroundImage: `url(${siteConfig?.backgroundImageUrl})`,
								minHeight: fullPage ? '100vh' : `calc(100vh - 64px)`,
							}
				}
			>
				<Page page="loan-app" title={getPageTitle()} noDarkMode>
					<div className="rounded-lg flex self-center content-center bg-white my-5 dark:bg-slate-800">
						{!!questions.length &&
							!fetchingInvite &&
							!saving &&
							!questionsOverride && (
								<img
									onClick={() =>
										!isLoanOfficerEntry
											? window.location.refresh
											: navigate(`/${navigationLinking.Portal}`)
									}
									className="w-48 lg:mt-16 mb-5 lg:mb-0 lg:w-72 lg:absolute lg:top-5 lg:right-5 select-none hidden lg:block"
									src={
										isDarkMode() && siteConfig?.darkModePortalLogoUrl
											? siteConfig.darkModePortalLogoUrl
											: siteConfig?.portalLogoUrl ||
												siteConfig?.logoUrl
									}
									alt={`${siteConfig.companyName} Logo`}
								/>
							)}
						{(user?.isLoggedIn ||
							(workflow.id &&
								workflow.authType !== 'RequireAuth')) && (
							<div className="flex flex-col items-center justify-center w-full py-6 px-6 sm:pb-12 sm:pt-10 md:pt-12 sm:px-12 relative">
								{!user?.isLoggedIn && (
									<div className="absolute top-2 left-2 cursor-pointer md:block hidden">
										<Home
											onClick={() =>
												navigate(navigationLinking.Landing)
											}
											fontSize="large"
										/>
									</div>
								)}

								{(!questions.length ||
									fetchingInvite ||
									submitted) && (
									<div className="flex justify-center w-64 md:w-96 mt-5">
										<Loading size="small" />
									</div>
								)}

								{!!questionsOverride && (
									<div className={`absolute top-1 right-1`}>
										<IconButton
											onClick={() =>
												editQuestion(
													questions.find((q) => target === q.qId)
												)
											}
										>
											<Edit />
										</IconButton>
									</div>
								)}

								<p className="pb-2 pt-2 font-bold text-base sm:text-2xl font-rubik dark:text-white select-none">
									{applicationTitle}
								</p>

								{!submitted &&
									!fetchingInvite &&
									!!questions.length &&
									workflow.showProgressBar && (
										<Box
											sx={{
												width: '100%',
												marginTop: '10px',
												marginBottom: '10px',
											}}
										>
											<LinearProgressWithLabel value={progress} />
										</Box>
									)}

								{!invalidJSON && (
									<>
										{applicationHeading.trim().length > 0 &&
										width > 468 ? (
											<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
												{!submitted
													? applicationHeading
													: 'Submitting Application'}
											</p>
										) : (
											<p className="text text-center mb-4 w-64 dark:text-white select-none">
												{!submitted
													? applicationHeading
													: 'Submitting Application'}
											</p>
										)}
									</>
								)}

								{invalidJSON ? (
									<p>Invalid JSON</p>
								) : (
									!submitted && (
										<>
											{saving && <Loading size={`small`} />}

											{!saving &&
												!finalTarget &&
												questions.map((q, index) => {
													if (q.qId !== target) return false

													questionText = replaceVars(q.question)

													switch (q.type) {
														case 'choice':
															return (
																<div
																	key={`${q.qId}-${index}-choice`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}
																	{q.options?.map((option, index) => {
																		return (
																			<CustomButton
																				id={`${routeName}${q.qId}${option.value}ChoiceAppButton`}
																				key={`${routeName}${q.qId}-${index}`}
																				text={
																					option.labelOverride ||
																					option.label
																				}
																				onClick={(e) =>
																					handleChoiceButtonPress(
																						e,
																						q,
																						option
																					)
																				}
																				variant={
																					app[q.fieldId] ===
																					option.value
																						? 'contained'
																						: 'outlined'
																				}
																				style={
																					fullScreen
																						? {
																								width: 256,
																								marginBottom: 10,
																								marginTop: 10,
																							}
																						: {
																								width: 384,
																								marginBottom: 10,
																								marginTop: 10,
																							}
																				}
																			/>
																		)
																	})}
																</div>
															)
														case 'boolean':
															return (
																<div
																	key={`${q.qId}-${index}-boolean`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}
																	{q.options?.map((option, index) => {
																		return (
																			<CustomButton
																				id={`${routeName}${q.qId}${option.value ? 'True' : 'False'}BooleanAppButton`}
																				key={`${routeName}${q.qId}-${index}-boolean`}
																				text={
																					option.labelOverride ||
																					option.label
																				}
																				onClick={(e) =>
																					handleChoiceButtonPress(
																						e,
																						q,
																						option
																					)
																				}
																				variant={
																					app[q.fieldId] ===
																					option.value
																						? 'contained'
																						: 'outlined'
																				}
																				style={
																					fullScreen
																						? {
																								width: 256,
																								marginBottom: 10,
																							}
																						: {
																								width: 384,
																								marginBottom: 10,
																								marginTop: 10,
																							}
																				}
																			/>
																		)
																	})}
																</div>
															)
														case 'text':
															return (
																<div
																	key={`${q.qId}-${index}-text`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}
																	<div className="flex-1 flex flex-row items-baseline w-64 sm:w-96 mb-4">
																		{[
																			'currency',
																			'fullCurrency',
																		].includes(q.validation) &&
																			app[q.fieldId] && (
																				<p className="mr-1 dark:text-white">
																					$
																				</p>
																			)}
																		<TextField
																			autoFocus={!!q.autoFocus}
																			onChange={(e) =>
																				handleInputChangeText(
																					e.target.value,
																					q
																				)
																			}
																			onKeyPress={(e) =>
																				handleInputKeyPress(e, q)
																			}
																			onBlur={(e) =>
																				handleInputBlur(e, q)
																			}
																			value={app[q.fieldId] || ''}
																			label={
																				!!app[q.fieldId]
																					? null
																					: q.label
																			}
																			placeholder={
																				q.placeholder || questionText
																			}
																			error={!!validationMessage}
																			helperText={
																				validationMessage ||
																				q.helperText
																			}
																			id={`${routeName}${q.qId}TextInput`}
																			inputProps={{
																				maxLength: q.maxLength,
																			}}
																			className="w-64 md:w-96 mb-4"
																			variant="standard"
																			multiline={q.multiline}
																			rows={q.rows || 10}
																		/>
																	</div>
																	<CustomButton
																		id={`${routeName}${q.qId}AppButton`}
																		text={
																			q.target
																				? q.buttonText || 'Continue'
																				: 'Finish'
																		}
																		onClick={(e) =>
																			handleInputButtonPress(e, q)
																		}
																		disabled={
																			inputButtonDisabled &&
																			!q.optional
																		}
																		variant="contained"
																		style={
																			fullScreen
																				? {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																				: {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																		}
																	/>
																</div>
															)
														case 'county':
															return (
																<div
																	key={`${q.qId}-${index}-text`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}
																	<div className="flex-1 flex flex-row items-baseline w-64 sm:w-96 mb-4">
																		{counties.length < 2 ? (
																			<TextField
																				autoFocus={!!q.autoFocus}
																				onChange={(e) =>
																					handleInputChangeText(
																						e.target.value,
																						q
																					)
																				}
																				onKeyPress={(e) =>
																					handleInputKeyPress(e, q)
																				}
																				onBlur={(e) =>
																					handleInputBlur(e, q)
																				}
																				value={app[q.fieldId] || ''}
																				label={
																					!!app[q.fieldId]
																						? null
																						: q.label
																				}
																				placeholder={
																					q.placeholder ||
																					questionText
																				}
																				error={!!validationMessage}
																				helperText={
																					validationMessage ||
																					q.helperText
																				}
																				id={`${routeName}${q.qId}TextInput`}
																				inputProps={{
																					maxLength: q.maxLength,
																				}}
																				className="w-64 md:w-96 mb-4"
																				variant="standard"
																			/>
																		) : (
																			<FormControl
																				id={`${routeName}${q.qId}Picker`}
																				variant="standard"
																				sx={[
																					width < 640
																						? { width: 256 }
																						: { width: 384 },
																					{
																						marginTop: app[q.fieldId]
																							? 1
																							: 0,
																						marginBottom: 1,
																					},
																				]}
																			>
																				<InputLabel>
																					County
																				</InputLabel>
																				<Select
																					value={app[q.fieldId] || ''}
																					onChange={(v) =>
																						handleCountyChange(
																							v.target.value,
																							q
																						)
																					}
																					label={q.default}
																				>
																					<MenuItem
																						key="default"
																						value=""
																					>
																						Choose a county
																					</MenuItem>
																					{counties.map(
																						(option, index) => (
																							<MenuItem
																								key={index}
																								value={option}
																							>
																								{option}
																							</MenuItem>
																						)
																					)}
																				</Select>
																			</FormControl>
																		)}
																	</div>
																	<CustomButton
																		id={`${routeName}${q.qId}AppButton`}
																		text={
																			q.target
																				? q.buttonText || 'Continue'
																				: 'Finish'
																		}
																		onClick={(e) =>
																			handleInputButtonPress(e, q)
																		}
																		disabled={
																			inputButtonDisabled &&
																			!q.optional
																		}
																		variant="contained"
																		style={
																			fullScreen
																				? {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																				: {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																		}
																	/>
																</div>
															)
														case 'picker':
															return (
																<div
																	key={`${q.qId}-${index}-picker`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}
																	<FormControl
																		id={`${routeName}${q.qId}Picker`}
																		variant="standard"
																		sx={[
																			width < 640
																				? { width: 256 }
																				: { width: 384 },
																			{
																				marginTop: app[q.fieldId]
																					? 1
																					: 0,
																				marginBottom: 1,
																			},
																		]}
																	>
																		<InputLabel>
																			{q.default || questionText}
																		</InputLabel>
																		<Select
																			value={app[q.fieldId] || ''}
																			onChange={(v) =>
																				handlePickerValueChange(
																					v.target.value,
																					q
																				)
																			}
																			label={q.default}
																		>
																			<MenuItem
																				key="default"
																				value=""
																			>
																				{q.default}
																			</MenuItem>
																			{q.options?.map(
																				(option, index) => (
																					<MenuItem
																						key={index}
																						value={option.value.toString()}
																					>
																						{option.labelOverride ||
																							option.label}
																					</MenuItem>
																				)
																			)}
																		</Select>
																	</FormControl>
																	<CustomButton
																		className="mt-5 w-full"
																		id={`${routeName}${q.qId}AppButton`}
																		text={
																			q.target ? 'Continue' : 'Finish'
																		}
																		onClick={(e) =>
																			handleInputButtonPress(e, q)
																		}
																		style={{
																			width: '100%',
																			marginBottom: 10,
																			marginTop: 20,
																		}}
																		disabled={
																			inputButtonDisabled &&
																			q.options?.length
																		}
																	/>
																</div>
															)
														case 'place':
															return (
																<div
																	key={`${q.qId}-${index}-place`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}
																	<Place
																		id={`${routeName}${q.qId}Place`}
																		placeholder={q.placeholder}
																		onChangeText={(value) =>
																			handleInputChangeText(value, q)
																		}
																		inputValue={app[q.fieldId]}
																		onPress={(p, d) =>
																			handleAddressSelection(p, d, q)
																		}
																		width={width}
																	/>
																	<CustomButton
																		className="mt-5 w-full"
																		id={`${routeName}${q.qId}AppButton`}
																		text={
																			q.target ? 'Continue' : 'Finish'
																		}
																		onClick={(e) =>
																			handleInputButtonPress(e, q)
																		}
																		style={
																			width < 640
																				? {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																				: {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																		}
																	/>
																</div>
															)
														case 'declaration':
															return (
																<div
																	key={`${q.qId}-${index}-declaration`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}
																	{(
																		q.options || [
																			{ label: 'Yes', value: true },
																			{ label: 'No', value: false },
																		]
																	).map((option, index) => {
																		return (
																			<CustomButton
																				id={`${routeName}${q.qId}${option.value}Declaration`}
																				key={`${q.qId}${option.label}-${index}`}
																				text={option.label}
																				onClick={(e) =>
																					handleChoiceButtonPress(
																						e,
																						q,
																						option
																					)
																				}
																				variant={
																					app[q.fieldId] ===
																					option.value
																						? 'contained'
																						: 'outlined'
																				}
																				style={
																					fullScreen
																						? {
																								width: 256,
																								marginBottom: 10,
																								marginTop: 10,
																							}
																						: {
																								width: 384,
																								marginBottom: 10,
																								marginTop: 10,
																							}
																				}
																			/>
																		)
																	})}
																</div>
															)
														case 'hmda':
															return (
																<div
																	key={`${q.qId}-${index}-hmda`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		width: width > 468 ? 384 : 256,
																	}}
																>
																	{q.options?.map((option, index) => {
																		return !option.other ? (
																			<FormControlLabel
																				label={
																					<Typography className="dark:text-white">
																						{option.label}
																					</Typography>
																				}
																				key={`${q.qId}${routeName}-${index}-hmda`}
																				className={`select-none ${option.indented && 'pl-5'}`}
																				control={
																					<Checkbox
																						id={`${routeName}${q.qId}${option.name}HMDACheckbox`}
																						checked={
																							app[q.fieldId] &&
																							app[q.fieldId][
																								option.name
																							]
																						}
																						onChange={() =>
																							handleHMDASelect(
																								option,
																								q
																							)
																						}
																						inputProps={{
																							'aria-label':
																								'controlled',
																						}}
																					/>
																				}
																			/>
																		) : (
																			<TextField
																				onChange={(e) =>
																					handleHMDAInputChangeText(
																						e.target.value,
																						option,
																						q
																					)
																				}
																				value={
																					(app[q.fieldId] &&
																						app[q.fieldId][
																							option.name
																						]) ||
																					''
																				}
																				label={
																					app[q.fieldId] &&
																					app[q.fieldId][option.name]
																						? option.label
																						: ''
																				}
																				placeholder={option.label}
																				id={`${routeName}${q.qId}${option.name}HMDAOtherInput`}
																				key={`${routeName}${q.qId}-${index}-hmda`}
																				className="w-64 md:w-96"
																				variant="standard"
																				//disabled={app[q.fieldId] && app[q.fieldId]['OptOut']}
																			/>
																		)
																	})}
																	{hmdaErrorMessage && (
																		<div className="text-center mt-3 mb-2 text-red-500">
																			{hmdaErrorMessage}
																		</div>
																	)}
																	<CustomButton
																		id={`${routeName}${q.qId}HMDAAppButton`}
																		text={
																			q.target ? 'Continue' : 'Finish'
																		}
																		onClick={(e) =>
																			handleHMDAButtonPress(e, q)
																		}
																		disabled={false}
																		variant="contained"
																		style={
																			fullScreen
																				? {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																				: {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																		}
																	/>
																</div>
															)
														case 'range':
															return (
																<div
																	key={`${q.qId}-${index}-range`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}
																	<div className="flex w-full items-center justify-center">
																		{q.lt && (
																			<p>
																				{(app[q.fieldId] === q.min ||
																					!app[q.fieldId]) &&
																					`Less than`}
																			</p>
																		)}
																		{q.prefix}
																		<TextField
																			onChange={(e) =>
																				handleInputChangeText(
																					e.target.value,
																					q
																				)
																			}
																			onKeyPress={(e) =>
																				handleInputKeyPress(e, q)
																			}
																			value={
																				app[q.fieldId] !==
																					undefined &&
																				app[q.fieldId] !== ''
																					? applyMask(
																							`${app[q.fieldId] || (q.lt ? q.min + 1 : q.min)}`,
																							'currency'
																						)
																					: ''
																			}
																			label={
																				!!app[q.fieldId]
																					? null
																					: q.label
																			}
																			placeholder={applyMask(
																				`${q.lt ? q.min + 1 : q.min}`,
																				'currency'
																			)}
																			id={`${routeName}${q.qId}TextInput`}
																			inputProps={{
																				maxLength: q.maxLength,
																			}}
																			className="!w-64 md:w-96"
																			variant="standard"
																			error={!!validationMessage}
																			helperText={
																				validationMessage ||
																				q.helperText
																			}
																			sx={{
																				width: q.width
																					? q.width
																					: width > 468
																						? '90%'
																						: 120,
																				padding: '0 10px',
																			}}
																		/>
																		{q.plus &&
																			app[q.fieldId] >= q.max &&
																			`+ `}
																		{app[q.fieldId] === 1 ||
																		(q.lt &&
																			(app[q.fieldId] === 0 ||
																				!app[q.fieldId]))
																			? q.suffix
																			: !!q.suffixPlural
																				? q.suffixPlural
																				: q.suffix}
																	</div>
																	<Box className="w-64 md:w-96 mt-2">
																		<Slider
																			aria-label="Default"
																			valueLabelDisplay="auto"
																			min={q.min}
																			max={q.max}
																			step={q.step}
																			value={parseInt(
																				`${app[q.fieldId] || q.min}`.replaceAll(
																					',',
																					''
																				)
																			)}
																			onChange={(e) =>
																				handleRangeSliderValueChange(
																					e.target.value,
																					q
																				)
																			}
																		/>
																	</Box>
																	<CustomButton
																		id={`${routeName}${q.qId}AppButton`}
																		text={
																			q.target ? 'Continue' : 'Finish'
																		}
																		onClick={(e) =>
																			handleRangeButtonPress(e, q)
																		}
																		variant="contained"
																		disabled={!!validationMessage}
																		style={
																			fullScreen
																				? {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																				: {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																		}
																	/>
																</div>
															)
														case 'information':
															return (
																<div
																	key={`${q.qId}-${index}-information`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-justify mb-4 w-96 dark:text-white select-none select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-justify mb-4 w-64 dark:text-white select-none select-none">
																			{questionText}
																		</p>
																	)}
																	<CustomButton
																		id={`${routeName}${q.qId}AppButton`}
																		text={
																			q.buttonText
																				? q.buttonText
																				: q.target
																					? 'Continue'
																					: 'Finish'
																		}
																		onClick={(e) =>
																			handleInputButtonPress(e, q)
																		}
																		variant="contained"
																		style={
																			fullScreen
																				? {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																				: {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																		}
																	/>
																</div>
															)
														case 'authorization':
															return (
																<div
																	key={`${q.qId}-${index}-authorization`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	<div
																		ref={authorizationRef}
																		className="h-64 w-96 overflow-auto border border-zinc-300 rounded-md px-3 py-3"
																		onScroll={
																			handleScrollAuthorization
																		}
																	>
																		<p className="text-sm text-slate-500 pointer-events-none select-none whitespace-pre-line">
																			{questionText}
																		</p>
																	</div>
																	{q.options?.length === 1 ? (
																		/** Single Authorization Choice */
																		<FormControlLabel
																			label={
																				<Typography className="dark:text-white">
																					{radioButtonDisabled &&
																					false
																						? 'Scroll to bottom to enable checkbox'
																						: q.options[0].text}
																				</Typography>
																			}
																			key={`${q.qId}-${index}-authorization`}
																			disabled={
																				radioButtonDisabled && false
																			}
																			control={
																				<Checkbox
																					id={`${routeName}${q.qId}${q.options[0].value ? 'True' : 'False'}AuthorizationCheckbox`}
																					checked={
																						app[q.fieldId] === true
																					}
																					onChange={() =>
																						handleSingleAuthCheckbox(
																							q.options[0],
																							q
																						)
																					}
																					inputProps={{
																						'aria-label':
																							'controlled',
																					}}
																				/>
																			}
																		/>
																	) : (
																		/** Multiple Authorization Choices */

																		<FormControl>
																			<RadioGroup
																				name="radio-buttons-group"
																				className="mt-2"
																				value={
																					isBoolean(app[q.fieldId])
																						? app[
																								q.fieldId
																							].toString()
																						: app[q.fieldId]
																				}
																				onChange={(e) => {
																					let val = e.target.value
																					if (val === 'true')
																						val = true
																					if (val === 'false')
																						val = false
																					handleRadioButtonChange(
																						q,
																						val
																					)
																				}}
																			>
																				{q.options?.map(
																					(option, index) => (
																						<FormControlLabel
																							control={
																								<Radio
																									size="small"
																									value={option.value}
																								/>
																							}
																							label={option.text}
																							key={index}
																							id={`${routeName}${q.qId}${option.value}AuthorizationRadioButton`}
																						/>
																					)
																				)}
																			</RadioGroup>
																		</FormControl>
																	)}
																	<CustomButton
																		id={`${routeName}${q.qId}AppButton`}
																		text={
																			q.target ? 'Continue' : 'Finish'
																		}
																		onClick={(e) =>
																			handleInputButtonPress(e, q)
																		}
																		disabled={
																			(q.options?.length === 1 &&
																				app[q.fieldId] !== true) ||
																			(q.options?.length > 1 &&
																				!isBoolean(app[q.fieldId]))
																		}
																		variant="contained"
																		style={
																			fullScreen
																				? {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																				: {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																		}
																	/>
																</div>
															)
														case 'privacyPolicyAuthorization':
															return (
																<div
																	key={`${q.qId}-${index}-authorization`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	<div
																		ref={authorizationRef}
																		className="h-64 w-96 overflow-auto"
																		onScroll={
																			handleScrollAuthorization
																		}
																	>
																		<p className="pointer-events-none select-none mb-10 whitespace-pre-line">
																			{questionText}
																		</p>
																		<Link
																			href={
																				siteConfig.privacyPolicyUrl
																			}
																		>
																			{siteConfig.companyName} Privacy
																			Policy
																		</Link>
																	</div>
																	{q.options?.length === 1 ? (
																		<FormControlLabel
																			label={
																				<Typography className="dark:text-white">
																					{radioButtonDisabled &&
																					false
																						? 'Scroll to bottom to enable checkbox'
																						: q.options[0].text}
																				</Typography>
																			}
																			key={`${q.qId}-${index}-authorization`}
																			disabled={
																				radioButtonDisabled && false
																			}
																			control={
																				<Checkbox
																					id={`${routeName}${q.qId}${q.options[0].value ? 'True' : 'False'}AuthorizationCheckbox`}
																					checked={
																						app[q.fieldId] === true
																					}
																					onChange={() =>
																						handleSingleAuthCheckbox(
																							q.options[0],
																							q
																						)
																					}
																					inputProps={{
																						'aria-label':
																							'controlled',
																					}}
																				/>
																			}
																		/>
																	) : (
																		<FormControl>
																			<RadioGroup
																				name="radio-buttons-group"
																				className="mt-2"
																			>
																				{q.options?.map(
																					(option, index) => (
																						<FormControlLabel
																							value={option.value}
																							control={
																								<Radio
																									size="small"
																									onChange={(e) =>
																										handleRadioButtonChange(
																											q,
																											e.target.value
																										)
																									}
																								/>
																							}
																							label={option.text}
																							key={index}
																							id={`${routeName}${q.qId}${option.value}AuthorizationRadioButton`}
																						/>
																					)
																				)}
																			</RadioGroup>
																		</FormControl>
																	)}
																	<CustomButton
																		id={`${routeName}${q.qId}AppButton`}
																		text={
																			q.target ? 'Continue' : 'Finish'
																		}
																		onClick={(e) =>
																			handleInputButtonPress(e, q)
																		}
																		disabled={app[q.fieldId] !== true}
																		variant="contained"
																		style={
																			fullScreen
																				? {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																				: {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																		}
																	/>
																</div>
															)
														case 'checkboxGroup':
															return (
																<div
																	key={`${q.qId}-${index}-hmda`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		width: width > 468 ? 384 : 256,
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}

																	{q.items.map((item, index) => {
																		const conditions =
																			item.hideIf?.split(' or ')
																		let hideItem = false

																		if (conditions)
																			for (
																				let i = 0;
																				i < conditions.length;
																				i++
																			) {
																				const condition =
																					conditions[i]
																				if (
																					testConditions(
																						condition,
																						app
																					)
																				)
																					hideItem = true
																			}

																		if (hideItem) return null

																		if (item.type !== 'text')
																			return (
																				<FormControlLabel
																					sx={{
																						alignItems: 'flex-start',
																					}}
																					label={
																						<Typography className="dark:text-white">
																							{item.label}
																						</Typography>
																					}
																					key={`${item.label}${routeName}-${index}-hmda`}
																					className={`select-none mt-5`}
																					control={
																						<Checkbox
																							id={`${routeName}${q.qId}${item.label}HMDACheckbox`}
																							checked={
																								app[item.fieldId] ===
																								'X'
																							}
																							onChange={() =>
																								handleCheckboxGroupCheckboxChange(
																									item
																								)
																							}
																							inputProps={{
																								'aria-label':
																									'controlled',
																							}}
																						/>
																					}
																				/>
																			)

																		return (
																			<TextField
																				onChange={(e) =>
																					handleInputChangeText(
																						e.target.value,
																						item
																					)
																				}
																				onKeyPress={(e) =>
																					handleInputKeyPress(e, item)
																				}
																				onBlur={(e) =>
																					handleInputBlur(e, item)
																				}
																				value={
																					app[item.fieldId] || ''
																				}
																				label={
																					!!app[item.fieldId]
																						? null
																						: item.label
																				}
																				placeholder={
																					item.placeholder ||
																					item.label
																				}
																				id={`${routeName}${item.qId}TextInput`}
																				inputProps={{
																					maxLength: item.maxLength,
																				}}
																				className="w-64 md:w-96 mb-4 pl-4"
																				variant="standard"
																			/>
																		)
																	})}
																	<CustomButton
																		id={`${routeName}${q.qId}HMDAAppButton`}
																		text={
																			q.target ? 'Continue' : 'Finish'
																		}
																		onClick={(e) =>
																			handleCheckboxGroupButtonPress(
																				e,
																				q
																			)
																		}
																		disabled={false}
																		variant="contained"
																		style={
																			fullScreen
																				? {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																				: {
																						width: '100%',
																						marginBottom: 10,
																						marginTop: 20,
																					}
																		}
																	/>
																</div>
															)
														case 'upload':
															return (
																<div
																	key={`${q.qId}-${index}-upload`}
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		alignItems: 'center',
																	}}
																>
																	{questionText && width > 468 ? (
																		<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																			{questionText}
																		</p>
																	) : (
																		<p className="text text-center mb-4 w-64 dark:text-white select-none">
																			{questionText}
																		</p>
																	)}
																	{!!app[q.fieldId] && (
																		<img
																			alt={app[q.fieldId]}
																			style={{
																				width:
																					app[
																						`${q.fieldId}Dimensions`
																					].width,
																				height:
																					app[
																						`${q.fieldId}Dimensions`
																					].height,
																				marginBottom: 20,
																			}}
																			src={app[q.fieldId]}
																		/>
																	)}
																	<Button
																		id={`${routeName}${q.qId}`}
																		variant="contained"
																		component="label"
																		style={
																			fullScreen
																				? {
																						width: 256,
																						marginBottom: 10,
																						textTransform: 'none',
																					}
																				: {
																						width: 384,
																						marginBottom: 10,
																						textTransform: 'none',
																					}
																		}
																	>
																		{`${!app[q.fieldId] ? 'Choose' : 'Change'} File`}
																		<input
																			onChange={(e) =>
																				handleImagePicker(e, q)
																			}
																			hidden
																			accept="image/*"
																			multiple
																			type="file"
																			name="myImage"
																		/>
																	</Button>
																	{!!app[q.fieldId] && (
																		<CustomButton
																			id={`${routeName}${q.qId}UploadContinueButton`}
																			text={`Accept & Continue`}
																			onClick={() =>
																				setNextTarget(q.target)
																			}
																			variant="contained"
																			style={
																				fullScreen
																					? {
																							width: '100%',
																							marginBottom: 10,
																							marginTop: 10,
																						}
																					: {
																							width: '100%',
																							marginBottom: 10,
																							marginTop: 10,
																						}
																			}
																		/>
																	)}
																</div>
															)
														case 'group':
															if (!!q.items && q.items.length) {
																return (
																	<div
																		key={`${q.qId}-${index}-group`}
																		style={{
																			display: 'flex',
																			flexDirection: 'column',
																			alignItems: 'center',
																		}}
																	>
																		{questionText && width > 468 ? (
																			<p className="text-lg text-center mb-4 w-96 dark:text-white select-none">
																				{questionText}
																			</p>
																		) : (
																			<p className="text text-center mb-4 w-64 dark:text-white select-none">
																				{questionText}
																			</p>
																		)}
																		{q.items.map((item, index) => {
																			const conditions =
																				item.hideIf?.split(' or ')
																			let hideItem = false

																			if (conditions)
																				for (
																					let i = 0;
																					i < conditions.length;
																					i++
																				) {
																					const condition =
																						conditions[i]
																					if (
																						testConditions(
																							condition,
																							app
																						)
																					)
																						hideItem = true
																				}

																			if (hideItem) return null

																			questionText = replaceVars(
																				item.question
																			)

																			switch (item.type) {
																				case 'row':
																					return (
																						<div
																							key={`${q.qId}-${index}`}
																							style={
																								width < 640
																									? {
																											display: 'flex',
																											flexDirection:
																												'column',
																											justifyContent:
																												'space-between',
																											alignItems:
																												'end',
																											width: 256,
																										}
																									: {
																											display: 'flex',
																											flexDirection:
																												'row',
																											justifyContent:
																												'space-between',
																											alignItems:
																												'end',
																											width: 384,
																										}
																							}
																						>
																							{item.items.map(
																								(child, index) => {
																									questionText =
																										replaceVars(
																											child.question
																										)
																									switch (
																										child.type
																									) {
																										case 'text':
																											return (
																												<div
																													key={`${q.qId}-${index}-text`}
																													style={
																														child.style
																													}
																												>
																													<div className="flex-1 flex flex-row justify-end items-baseline mb-2 w-64 md:w-48">
																														{child.validation ===
																															'currency' &&
																															app[
																																child
																																	.fieldId
																															] && (
																																<p className="mr-1 dark:text-white">
																																	$
																																</p>
																															)}
																														<TextField
																															autoFocus={
																																!!q.autoFocus
																															}
																															onChange={(
																																e
																															) =>
																																handleGroupInputChangeText(
																																	e
																																		.target
																																		.value,
																																	child,
																																	q
																																)
																															}
																															onBlur={(
																																e
																															) =>
																																handleGroupInputBlur(
																																	e,
																																	child,
																																	q
																																)
																															}
																															value={
																																app[
																																	child
																																		.fieldId
																																] ||
																																''
																															}
																															label={
																																!app[
																																	child
																																		.fieldId
																																]
																																	? null
																																	: questionText
																															}
																															placeholder={
																																questionText
																															}
																															error={
																																!!groupValidations[
																																	child
																																		.qId
																																]
																															}
																															helperText={
																																groupValidations[
																																	child
																																		.qId
																																] ||
																																child.helperText
																															}
																															id={`${routeName}${q.qId}${item.qId}${child.qId}Input`}
																															inputProps={{
																																maxLength:
																																	q.maxLength,
																															}}
																															className="w-64 md:w-full"
																															variant="standard"
																															multiline={
																																q.multiline
																															}
																															rows={
																																q.rows ||
																																10
																															}
																														/>
																													</div>
																												</div>
																											)
																										case 'picker':
																											return (
																												<div
																													key={`${q.qId}-${index}-picker`}
																													style={
																														child.style
																													}
																												>
																													<FormControl
																														id={`${routeName}${q.qId}${child.qId}Picker`}
																														variant="standard"
																														sx={[
																															width <
																															640
																																? {
																																		width: 256,
																																		marginBottom: 1,
																																	}
																																: {
																																		width: 176,
																																	},
																															{
																																marginTop:
																																	app[
																																		child
																																			.fieldId
																																	]
																																		? 1
																																		: 0,
																																marginBottom: 1,
																															},
																														]}
																													>
																														<InputLabel
																															className={`select-none`}
																														>
																															{q.default ||
																																questionText}
																														</InputLabel>
																														<Select
																															value={
																																app[
																																	child
																																		.fieldId
																																] ||
																																''
																															}
																															onChange={(
																																v
																															) =>
																																handleGroupPickerValueChange(
																																	v
																																		.target
																																		.value,
																																	item,
																																	q
																																)
																															}
																															label={
																																q.default
																															}
																														>
																															<MenuItem
																																key="default"
																																value=""
																															>
																																{
																																	child.default
																																}
																															</MenuItem>
																															{child.options.map(
																																(
																																	option,
																																	index
																																) => (
																																	<MenuItem
																																		key={
																																			index
																																		}
																																		value={option.value.toString()}
																																	>
																																		{option.labelOverride ||
																																			option.label}
																																	</MenuItem>
																																)
																															)}
																														</Select>
																													</FormControl>
																												</div>
																											)
																										default:
																											return <></>
																									}
																								}
																							)}
																						</div>
																					)
																				case 'range':
																					return (
																						<div
																							key={`${q.qId}-${index}-range`}
																							style={item.style}
																						>
																							<Box
																								id={`${routeName}${q.qId}${item.qId}RangeSlider`}
																								className="w-64 md:w-96 mt-2"
																							>
																								<Slider
																									aria-label="Default"
																									valueLabelDisplay="auto"
																									min={item.min}
																									max={item.max}
																									step={item.step}
																									value={parseInt(
																										`${app[item.fieldId] || item.min}`.replaceAll(
																											',',
																											''
																										)
																									)}
																									onChange={(e) =>
																										handleRangeSliderValueChange(
																											e.target.value,
																											q
																										)
																									}
																								/>
																							</Box>
																							<div className="flex w-full items-center justify-center">
																								{item.prefix}{' '}
																								{app[item.fieldId] ||
																									item.min}{' '}
																								{item.suffix}
																							</div>
																						</div>
																					)
																				case 'text':
																					return (
																						<div
																							className="mt-2"
																							key={`${q.qId}-${index}-text`}
																						>
																							<div className="flex-1 flex flex-row items-baseline w-64 sm:w-96 mb-4">
																								{item.validation ===
																									'currency' &&
																									app[
																										item.fieldId
																									] && (
																										<p className="mr-1 dark:text-white">
																											$
																										</p>
																									)}
																								<TextField
																									autoFocus={
																										!!q.autoFocus
																									}
																									onChange={(e) =>
																										handleGroupInputChangeText(
																											e.target.value,
																											item,
																											q
																										)
																									}
																									onBlur={(e) =>
																										handleGroupInputBlur(
																											e,
																											item,
																											q
																										)
																									}
																									value={
																										app[
																											item.fieldId
																										] || ''
																									}
																									label={
																										!app[item.fieldId]
																											? null
																											: questionText
																									}
																									placeholder={
																										questionText
																									}
																									error={
																										!!groupValidations[
																											item.qId
																										]
																									}
																									helperText={
																										groupValidations[
																											item.qId
																										] ||
																										item.helperText
																									}
																									id={`${routeName}${q.qId}${item.qId}Input`}
																									inputProps={{
																										maxLength:
																											item.maxLength,
																									}}
																									className="w-64 sm:w-96"
																									variant="standard"
																									multiline={
																										q.multiline
																									}
																									rows={q.rows || 10}
																								/>
																							</div>
																						</div>
																					)
																				case 'county':
																					return (
																						<div
																							className="mt-2"
																							key={`${q.qId}-${index}-text`}
																						>
																							<div className="flex-1 flex flex-row items-baseline w-64 sm:w-96 mb-4">
																								{[
																									'currency',
																									'fullCurrency',
																								].includes(
																									item.validation
																								) &&
																									app[
																										item.fieldId
																									] && (
																										<p className="mr-1 dark:text-white">
																											$
																										</p>
																									)}
																								{counties.length <
																								2 ? (
																									<TextField
																										autoFocus={
																											!!q.autoFocus
																										}
																										onChange={(e) =>
																											handleGroupInputChangeText(
																												e.target
																													.value,
																												item,
																												q
																											)
																										}
																										onBlur={(e) =>
																											handleGroupInputBlur(
																												e,
																												item,
																												q
																											)
																										}
																										value={
																											app[
																												item.fieldId
																											] || ''
																										}
																										label={
																											!app[
																												item.fieldId
																											]
																												? null
																												: questionText
																										}
																										placeholder={
																											questionText
																										}
																										error={
																											!!groupValidations[
																												item.qId
																											]
																										}
																										helperText={
																											groupValidations[
																												item.qId
																											] ||
																											item.helperText
																										}
																										id={`${routeName}${q.qId}${item.qId}Input`}
																										inputProps={{
																											maxLength:
																												item.maxLength,
																										}}
																										className="w-64 sm:w-96"
																										variant="standard"
																									/>
																								) : (
																									<FormControl
																										id={`${routeName}${q.qId}${item.qId}Picker`}
																										variant="standard"
																										sx={[
																											width < 640
																												? {
																														width: 256,
																													}
																												: {
																														width: 384,
																													},
																											{
																												marginTop:
																													app[
																														item
																															.fieldId
																													]
																														? 1
																														: 0,
																												marginBottom: 1,
																											},
																										]}
																									>
																										<InputLabel
																											className={`select-none`}
																										>
																											County
																										</InputLabel>
																										<Select
																											value={
																												app[
																													item.fieldId
																												] || ''
																											}
																											onChange={(v) =>
																												handleCountyChange(
																													v.target
																														.value,
																													item,
																													q
																												)
																											}
																											label={
																												item.default
																											}
																										>
																											{!app[
																												item.fieldId
																											] && (
																												<MenuItem
																													key="default"
																													value=""
																												>
																													Choose a
																													County
																												</MenuItem>
																											)}
																											{counties.map(
																												(
																													option,
																													index
																												) => (
																													<MenuItem
																														key={
																															index
																														}
																														value={
																															option
																														}
																													>
																														{option}
																													</MenuItem>
																												)
																											)}
																										</Select>
																									</FormControl>
																								)}
																							</div>
																						</div>
																					)
																				case 'picker':
																					return (
																						<div
																							key={`${routeName}${q.qId}-${index}-picker`}
																							className="flex flex-col items-center"
																						>
																							<FormControl
																								id={`${routeName}${q.qId}${item.qId}Picker`}
																								variant="standard"
																								sx={[
																									width < 640
																										? { width: 256 }
																										: { width: 384 },
																									{
																										marginTop: app[
																											item.fieldId
																										]
																											? 1
																											: 0,
																										marginBottom: 1,
																									},
																								]}
																							>
																								<InputLabel
																									className={`select-none`}
																								>
																									{item.default ||
																										questionText}
																								</InputLabel>
																								<Select
																									value={
																										app[
																											item.fieldId
																										] || ''
																									}
																									onChange={(v) =>
																										handleGroupPickerValueChange(
																											v.target.value,
																											item,
																											q
																										)
																									}
																									label={item.default}
																								>
																									{!app[
																										item.fieldId
																									] && (
																										<MenuItem
																											key="default"
																											value=""
																										>
																											{item.default}
																										</MenuItem>
																									)}
																									{item.options.map(
																										(
																											option,
																											index
																										) => (
																											<MenuItem
																												key={index}
																												value={option.value.toString()}
																											>
																												{option.labelOverride ||
																													option.label}
																											</MenuItem>
																										)
																									)}
																								</Select>
																							</FormControl>
																						</div>
																					)
																				case 'place':
																					return (
																						<div
																							key={`${item.fieldId}-place`}
																							className="flex flex-col items-center"
																						>
																							{app[item.fieldId] && (
																								<p className="mb-5 mx-10 text-center select-none">
																									{questionText}
																								</p>
																							)}
																							<Place
																								id={`${routeName}${q.qId}${item.qId}Place`}
																								placeholder={
																									item.placeholder
																								}
																								onChangeText={(
																									value
																								) =>
																									handleGroupInputChangeText(
																										value,
																										item,
																										q
																									)
																								}
																								inputValue={
																									app[item.fieldId]
																								}
																								onPress={(p, d) =>
																									handleAddressSelection(
																										p,
																										d,
																										item,
																										'individual'
																									)
																								}
																								width={width}
																							/>
																						</div>
																					)
																				default:
																					return <></>
																			}
																		})}
																		<div className="mt-4 w-full">
																			<CustomButton
																				className="mt-5"
																				id={`${routeName}${q.qId}AppButton`}
																				text={
																					q.target
																						? q.buttonText ||
																							'Continue'
																						: 'Finish'
																				}
																				onClick={(e) =>
																					handleGroupButtonPress(e, q)
																				}
																				style={{
																					width: '100%',
																					marginBottom: 10,
																					marginTop: 10,
																				}}
																				disabled={
																					groupInputButtonDisabled
																				}
																			/>
																		</div>
																	</div>
																)
															}
															break
														default:
															return <></>
													}
													return <></>
												})}

											{targetHistory.length > 1 && (
												<div
													className={`justify-self-start w-full mt-10 cursor-pointer`}
													onClick={setPrevTarget}
												>
													<ArrowBack /> Back
												</div>
											)}
										</>
									)
								)}
							</div>
						)}
					</div>
				</Page>
				{(theme.isLocalHost ||
					searchParams.get(`_debug`) === 'verbose') &&
					!questionsOverride &&
					!!workflow?.id && (
						<div
							className={`absolute bottom-0 py-3 px-6 text-white bg-slate-600 w-full`}
						>
							<div className={`w-full flex justify-between`}>
								<div>
									<span className={`text-gray-900`}>Form ID:</span>{' '}
									{workflow.id}
								</div>
								<div>
									<span className={`text-gray-900`}>Form Name:</span>{' '}
									{workflow.name}
								</div>
								<div>
									<span className={`text-gray-900`}>Form Type:</span>{' '}
									{formTypes[workflow.type]}
								</div>
								<div>
									<span className={`text-gray-900`}>
										Borrower Type:
									</span>{' '}
									{borrowerTypes[workflow.borrowerType]}
								</div>
								<div>
									<span className={`text-gray-900`}>Is Default:</span>{' '}
									{workflow.isDefault ? `Yes` : `No`}
								</div>
								<div>
									<span className={`text-gray-900`}>Auth Type:</span>{' '}
									{workflow.authType}
								</div>
								<div>
									<span className={`text-gray-900`}>Target:</span>{' '}
									{workflow.target}
								</div>
								<div>
									{!debugWindowOpen ? (
										<ArrowUpwardOutlined
											className={`cursor-pointer`}
											onClick={() => setDebugWindowOpen(true)}
										/>
									) : (
										<ArrowDownwardOutlined
											className={`cursor-pointer`}
											onClick={() => setDebugWindowOpen(false)}
										/>
									)}
								</div>
							</div>
							<div
								className={`${!debugWindowOpen ? `p-0` : `p-3`} text-white bg-gray-950 w-full transition ease-in-out delay-300 overflow-scroll ${!debugWindowOpen ? `h-0` : `h-48`}`}
							>
								{debugWindowOpen &&
									iterateObject(app).map(({ key, value }) => (
										<div>
											<span className={`text-gray-300`}>{key}:</span>{' '}
											{typeof value === 'boolean'
												? value
													? 'true'
													: 'false'
												: value}
										</div>
									))}
							</div>
						</div>
					)}
			</div>
		</>
	)
}
