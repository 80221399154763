import React, { useMemo } from 'react'
import {
	TextField as MuiTextField,
	TextFieldProps as MuiTextFieldProps,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import type { ControllerProps, FieldErrors } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel'
import { merge } from 'lodash'
import useFormField from '../../hooks/useFormField'

export type TextFieldProps = {
	name?: string
	control?: ControllerProps['control']
	errors?: FieldErrors
	onClear?: () => void
	label: string
} & Omit<MuiTextFieldProps, 'label'>

const TextField = ({
	variant = 'standard',
	placeholder,
	label,
	control,
	required,
	name,
	errors,
	onChange,
	onClear,
	value,
	InputProps,
	helperText,
	...rest
}: TextFieldProps) => {
	const { getError } = useFormField()
	const error = getError(name, errors)
	const placeholderTxt = placeholder || label
	const labelTxt = required && label ? `${label} *` : label

	const clearEndAdornment = useMemo(
		() => (
			<div className="pr-2">
				<IconButton edge="end" onClick={onClear} size="small">
					<CancelIcon fontSize="small" />
				</IconButton>
			</div>
		),
		[onClear]
	)

	const inputProps = useMemo(() => {
		return onClear && value
			? merge({ endAdornment: clearEndAdornment }, InputProps)
			: InputProps
	}, [InputProps, clearEndAdornment, onClear, value])

	if (name && control) {
		return (
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<MuiTextField
						variant={variant}
						label={labelTxt}
						placeholder={placeholderTxt}
						error={!!error}
						helperText={error ? error.message : helperText}
						{...field}
						InputProps={inputProps}
						{...rest}
					/>
				)}
			/>
		)
	}

	return (
		<MuiTextField
			name={name}
			variant={variant}
			label={labelTxt}
			placeholder={placeholderTxt}
			error={!!error}
			helperText={error ? error.message : helperText}
			onChange={onChange}
			value={value}
			InputProps={inputProps}
			{...rest}
		/>
	)
}

TextField.defaultProps = {
	name: undefined,
	control: undefined,
	errors: undefined,
	onClear: undefined,
}

export default TextField
